import { useTheme } from "@chakra-ui/react";
import {
  faFolderClosed,
  faRocket,
  faChartLine,
  faPerson,
  faShieldHalved,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";

export const FeaturesColumnComponent = () => {
  const theme = useTheme();

  const featureItems = [
    {
      icon: faPerson,
      heading: "Advanced clarity and accessibility",
      subtitle:
        "The Plain Writing Act of 2010 was signed on October 13, 2010. The law requires that federal agencies use clear government communication that the public can understand and use.",
    },
    {
      icon: faFolderClosed,
      heading: "Document version control",
      subtitle:
        "Efficiently manage document versions, facilitating seamless updates and collaboration across your agency.",
    },
    {
      icon: faRocket,
      heading: "Automated summary insights",
      subtitle:
        "Quickly grasp complex documents through AI-generated summaries, improving decision-making and engagement.",
    },
    {
      icon: faChartLine,
      heading: "Immediate analysis",
      subtitle:
        "Gain instant insights to enhance readability and compliance, making your documents more effective.",
    },
    {
      icon: faShieldHalved,
      heading: "Data privacy and security compliance",
      subtitle:
        "Securely handle sensitive information while complying with global data protection standards.",
    },
    {
      icon: faRobot,
      heading: "Evolving AI",
      subtitle:
        "Benefit from an AI that learns from your content, continuously improving compliance and readability.",
    },
  ];

  return (
    <S.StyledFeaturesColumnContainer>
      <S.StyledFeaturesHeadingSmall>Features</S.StyledFeaturesHeadingSmall>
      <S.StyledFeaturesHeadingBig>
        What can you expect?
      </S.StyledFeaturesHeadingBig>
      <S.StyledGridContainer>
        {featureItems.map((item, index) => (
          <S.StyledIconBoxContainer index={index} key={index}>
            <S.StyledIconBackgroundBox bg={theme.colors.accentTwo}>
              <S.StyledIcon icon={item.icon} color={theme.colors.accentOne} />
            </S.StyledIconBackgroundBox>
            <S.StyledItemHeading>{item.heading}</S.StyledItemHeading>
            <S.StyledItemText>{item.subtitle}</S.StyledItemText>
          </S.StyledIconBoxContainer>
        ))}
      </S.StyledGridContainer>
    </S.StyledFeaturesColumnContainer>
  );
};
