import { Box, HStack, Button, Image, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

export const StyledHeaderContainer = ({ children, bgColor }) => {
  return (
    <Box
      py="2"
      pr={{ base: 6, md: 20 }}
      pl={{ base: 6, md: 20 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      w="full"
      height="64px"
      bg={bgColor}
      position="fixed"
      top="0"
      zIndex={99}
    >
      {children}
    </Box>
  );
};

export const StyledOuterHStack = ({ children }) => {
  return <HStack spacing={{ base: 0, md: 6 }}>{children}</HStack>;
};

export const StyledHamburgerMenuButton = ({ handleToggleMenu }) => {
  return (
    <IconButton
      aria-label="Menu"
      icon={<HamburgerIcon />}
      onClick={handleToggleMenu}
      display={{ base: "flex", md: "none" }}
    />
  );
};

export const StyledMenuContainer = ({ children, bgColor }) => {
  return (
    <Box
      position={{ base: "absolute", md: "relative" }}
      left="0"
      top={{ base: "50px", md: "auto" }}
      width={{ base: "100%", md: "auto" }}
      pt={{ base: 5, md: 0 }}
      pb={{ base: 5, md: 0 }}
      bg={bgColor}
    >
      {children}
    </Box>
  );
};

export const StyledInnerMenuHStack = ({ children, showMenu }) => {
  return (
    <HStack
      spacing="8"
      display={{ base: showMenu ? "flex" : "none", md: "flex" }}
      flexDirection={{ base: "column", md: "row" }}
    >
      {children}
    </HStack>
  );
};

export const StyledContactUsButton = ({ children, onClick, bgColor }) => {
  return (
    <Button bg={bgColor} color="white" onClick={onClick}>
      {children}
    </Button>
  );
};

export const StyledLogo = ({ logo, onClick }) => {
  return (
    <Image
      width={180}
      height={50}
      fit="contain"
      src={logo}
      alt="PlainLanguagePro Logo"
      cursor="pointer"
      onClick={onClick}
    />
  );
};
