import React, { useRef } from "react";
import { useTheme } from "@chakra-ui/react";
import plainLanguageProBlack from "../../assets/PlainLanguageProBlack.png";
import { StyledHeaderComponent } from "./components/styledHeaderComponent";
import { HeroComponent } from "./components/heroComponent";
import { StyledFooterComponent } from "./components/styledFooterComponent";
import { ContactUsComponent } from "./components/contactUsComponent";
import { WhyUsComponent } from "./components/whyUsComponent";
import { FeaturesHeadlineComponent } from "./components/featuresHeadlineComponent";
import { FeaturesColumnComponent } from "./components/featuresColumnComponent";
import { TilesComponent } from "./components/tilesComponent";
import * as S from "./styles";

export const LandingPage = () => {
  const theme = useTheme();

  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const complianceRef = useRef(null);
  const whyUsRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <S.StyledLandingPageContainer>
      <StyledHeaderComponent
        bgColor={theme.colors.primary}
        logo={plainLanguageProBlack}
        onFeatureClick={() => scrollToRef(featuresRef)}
        onComplianceClick={() => scrollToRef(complianceRef)}
        onWhyUsClick={() => scrollToRef(whyUsRef)}
        onContactUsClick={() => scrollToRef(contactUsRef)}
        onLogoClick={() => scrollToRef(heroRef)}
      />
      <S.StyledHeroContainer refProp={heroRef}>
        <HeroComponent onContactUsClick={() => scrollToRef(contactUsRef)} />
      </S.StyledHeroContainer>
      <S.StyledTilesContainer>
        <TilesComponent />
      </S.StyledTilesContainer>
      <S.StyledFeatureColumnContainer refProp={featuresRef}>
        <FeaturesColumnComponent />
      </S.StyledFeatureColumnContainer>
      <S.StyledFeatureHeadlineContainer refProp={complianceRef}>
        <FeaturesHeadlineComponent />
      </S.StyledFeatureHeadlineContainer>
      <S.StyledWhyUsContainer refProp={whyUsRef}>
        <WhyUsComponent />
      </S.StyledWhyUsContainer>
      <S.StyledContactUsContainer refProp={contactUsRef}>
        <ContactUsComponent />
      </S.StyledContactUsContainer>
      <StyledFooterComponent
        bgColor={theme.colors.primary}
        logo={plainLanguageProBlack}
      />
    </S.StyledLandingPageContainer>
  );
};
