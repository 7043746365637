import React, { useState, useRef, useEffect } from "react";
import { Box, Modal, ModalContent, Input, Flex } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { ButtonComponent } from "../buttonComponent";
import { TinyMcEditorComponent } from "../tinymcEditorComponent";
import useCustomToast from "../../hooks/useCustomToast";
import { FetchPreSignedUrl, UploadVersionToS3HTML } from "../../api/s3";
import {
  GetFolderId,
  CreateDocument,
  UpdateDocument,
} from "../../api/plainLanguageHandler";
import { callFileExtractor } from "../../api/callFileExtractor";

export const CreateInputProjectComponent = ({
  user,
  isOpen,
  onClose,
  fetchProjects,
}) => {
  const showToast = useCustomToast();
  const inputProjectEditorRef = useRef(null);
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const modalContentRef = React.useRef(); // Ref for the modal content
  const [initialEditorState, setInitialEditorState] = useState(null);
  const [inputProjectTitle, setInputProjectTitle] = useState("");

  const handleCreateAndProcess = async () => {
    if (!initialEditorState) {
      showToast("Editor must not be empty", "error", 5000);
    } else {
      const folderId = await GetFolderId(user.email);
      const documentName = `${inputProjectTitle}.html`;

      const s3Key = `${folderId}/${inputProjectTitle}/${documentName}`;

      let url = await FetchPreSignedUrl(s3Key, "putObject");

      // Convert HTML content to a Blob
      const blob = new Blob([initialEditorState], {
        type: "text/html",
      });

      // Convert Blob to ArrayBuffer for upload to S3
      const arrayBuffer = await blob.arrayBuffer();

      // Upload the HTML file to S3
      const uploadResult = await UploadVersionToS3HTML(url, arrayBuffer);

      // Fetch pre-signed URL for retrieving the uploaded HTML file
      url = await FetchPreSignedUrl(s3Key, "getObject");

      //create project in DB
      const createDocResult = await CreateDocument(
        user,
        ["Army"],
        inputProjectTitle,
        folderId,
        documentName
      );

      // run plain language process
      if (createDocResult.documentId) {
        callFileExtractor(
          process.env.REACT_APP_PLAINLANGUAGE_S3_BUCKET,
          s3Key,
          url,
          createDocResult.documentId
        )
          .then((metrics) => {
            UpdateDocument(metrics, createDocResult.documentId);
          })
          .catch((err) => {
            console.error(`Error extracting file: ${err}`);
          });
      }

      showToast("Project created successfully", "success", 5000);
      fetchProjects();
      onClose();
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      closeOnOverlayClick={false}
    >
      <ModalContent ref={modalContentRef} alignItems={"center"}>
        <Box width={"80%"} height={"80%"} padding={6}>
          <Flex justifyContent={"space-between"}>
            <Input
              placeholder="Project Name"
              value={inputProjectTitle}
              onChange={(e) => setInputProjectTitle(e.target.value)}
              marginBottom={6}
              width={"80%"}
              justifySelf={"center"}
              sx={{
                "::placeholder": {
                  color: "grey",
                },
              }}
            />
            <ButtonComponent
              isDisabled={!inputProjectTitle}
              onClick={handleCreateAndProcess}
            >
              Create & Process
            </ButtonComponent>
            <ButtonComponent
              bgColor={"red"}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to discard this project?"
                  )
                ) {
                  onClose();
                  setInputProjectTitle("");
                }
              }}
            >
              <CloseIcon />
            </ButtonComponent>
          </Flex>
          <TinyMcEditorComponent
            editorRef={inputProjectEditorRef}
            initialEditorState={initialEditorState}
            setInitialEditorState={setInitialEditorState}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
};
