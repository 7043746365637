import { useState } from "react";
import {
  Box,
  Text,
  useTheme,
  Input,
  Textarea,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import * as S from "./styles";

export const ContactUsComponent = () => {
  const theme = useTheme();

  let captchaValidated = false;
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    acceptTerms: false,
  });

  const contactUsHandler = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_CONTACT_US_API_URL,
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }
      );

      if (response.data.statusCode === 200 && response.data.body) {
        const body = JSON.parse(response.data.body);
        console.log(body.message);
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const recaptchHandler = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        process.env.REACT_APP_PLAINLANGUAGE_RECAPTCHA_API_URL,
        {
          token: token,
        }
      );

      if (response.data.statusCode === 200 && response.data.body) {
        const body = JSON.parse(response.data.body);
        console.log(body.message);
        captchaValidated = true;
      } else {
        console.error("Error verifying captcha:", response);
        captchaValidated = false;
      }
    } catch (error) {
      console.error("Error verifying captcha:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (!formData.acceptTerms) {
      setToken(null);
    }
  };

  const handleTokenChange = (value) => {
    setToken(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (token) {
      await recaptchHandler();

      if (captchaValidated) {
        await contactUsHandler();
      } else {
        console.log("reCAPTCHA was not validated");
      }
    } else {
      console.error("reCAPTCHA token not available");
    }

    // reset state
    captchaValidated = false;
    setIsLoading(false);
    setToken(null);
    setFormData({
      name: "",
      email: "",
      message: "",
      acceptTerms: false,
    });
  };

  const isSubmitDisabled = !formData.acceptTerms || !token;

  return (
    <S.StyledFeaturesColumnContainer>
      <Box flex={{ base: "1" }}>
        <S.StyledFeaturesHeadingSmall>Contact us</S.StyledFeaturesHeadingSmall>
        <S.StyledFeaturesHeadingBig>Get in touch</S.StyledFeaturesHeadingBig>
        <Box>
          <S.StyledSubtitleText>
            Discover how PlainLanguagePro can transform your agency's
            communication. Contact us today to learn more or start your trial.
          </S.StyledSubtitleText>
          <Box as="form" onSubmit={handleSubmit} pt="6">
            <FormControl id="name" isRequired pb="4">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl id="email" isRequired pb="4">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl id="message" isRequired pb="4">
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Fill in your message"
              />
            </FormControl>

            <FormControl
              id="acceptTerms"
              isRequired
              pb={formData.acceptTerms ? 4 : 0}
            >
              <Checkbox
                name="acceptTerms"
                isChecked={formData.acceptTerms}
                onChange={handleInputChange}
                borderColor={theme.colors.gray}
              >
                Please reach out to me
              </Checkbox>
            </FormControl>
            {formData.acceptTerms && (
              <FormControl id="recaptcha" isRequired>
                <ReCAPTCHA
                  sitekey={
                    process.env.REACT_APP_PLAINLANGUAGE_GOOGLE_RECAPCHA_SITE_KEY
                  }
                  onChange={handleTokenChange}
                />
              </FormControl>
            )}
            {isLoading ? (
              <Box width="40px" height="40px" alignItems={"center"} mt={4}>
                <Spinner size="xl" />
              </Box>
            ) : (
              <Button
                type="submit"
                mt={4}
                background={theme.colors.accentOne}
                color={theme.colors.primary}
                isDisabled={isSubmitDisabled}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box flex={{ base: "0", md: "0.5" }}></Box>
    </S.StyledFeaturesColumnContainer>
  );
};
