import React, { useState } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as routes from "../../constants/routes";

const ObfuscationPage = ({ onObfuscationPassed, path }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setError(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_OBFUSCATION_API, {
        code: code,
      });
      const data = response.data;
      const body = JSON.parse(data.body);
      if (body.message === "Obfuscation passed") {
        onObfuscationPassed();
        navigate(routes.LOGIN, { replace: true });
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Obfuscation error:", error);
      setError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        boxSize="sm"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <FormControl id="code" mb={4}>
          <FormLabel srOnly>Enter Code</FormLabel>
          <InputGroup size="md">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Enter code"
              value={code}
              onKeyPress={handleKeyPress}
              onChange={handleCodeChange}
              isInvalid={error}
              borderColor={error ? "red.500" : "gray.300"}
            />
            <InputRightElement>
              <IconButton
                size="sm"
                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handlePasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {error && (
          <Text color="red.500" mb={4}>
            Incorrect code. Please try again.
          </Text>
        )}
        <Button onClick={handleSubmit}>Submit</Button>
      </Box>
    </Box>
  );
};

export default ObfuscationPage;
