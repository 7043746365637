import { Box, Heading, Text, Button } from "@chakra-ui/react";

export const StyledHeroContainer = ({ children, bgColor }) => {
  return (
    <Box
      bg={bgColor}
      pt={{ base: "36" }}
      pl={{ base: "5", sm: "20" }}
      pr={{ base: "5", sm: "20" }}
      pb={{ base: "20" }}
      borderRadius="md"
    >
      {children}
    </Box>
  );
};

export const StyledOuterFlexBoxContainer = ({ children }) => {
  return <Box flex="1">{children}</Box>;
};

export const StyledHeroHeading = ({ children }) => {
  return (
    <Heading as="h1" size={{ base: "lg", md: "2xl" }} mb={6}>
      {children}
    </Heading>
  );
};

export const StyledSubtitleContainer = ({ children }) => {
  return <Box w="90%">{children}</Box>;
};

export const StyledSubtitleText = ({ children }) => {
  return (
    <Text fontSize="md" mb={6} whiteSpace="pre-wrap">
      {children}
    </Text>
  );
};

export const StyledHeroButton = ({
  children,
  bgColor,
  color,
  fontFamily,
  onClick,
}) => {
  return (
    <Button
      bg={bgColor}
      color={color}
      size="md"
      fontSize="sm"
      fontFamily={fontFamily}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const StyledEmptySpaceContainer = () => {
  return <Box flex={{ base: "0", md: "0.40" }} />;
};
