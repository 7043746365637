import { React, useState } from "react";
import {
  Button,
  Box,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";
import { FetchPreSignedUrl, UploadVersionToS3HTML } from "../../../api/s3";
import {
  CreateProjectVersion,
  StartDocumentVersionProcessing,
  UpdateDocumentVersion,
} from "../../../api/plainLanguageHandler";
import useCustomToast from "../../../hooks/useCustomToast";

export const CreateVersionComponent = ({
  htmlContent,
  project,
  versionsList,
  user,
  setIsVersionPolling,
  editorLoadingState,
}) => {
  const showToast = useCustomToast();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newVersionName, setNewVersionName] = useState("");
  const [error, setError] = useState(null);

  const generateAndUploadHtml = async (htmlContent, versionName) => {
    try {
      // Fetch pre-signed URL for uploading the HTML file
      const newPutVersionPreSignedUrl = await fetchNewVersionPreSignedUrl(
        versionName,
        "putObject"
      );

      // Convert HTML content to a Blob
      const blob = new Blob([htmlContent], { type: "text/html" });

      // Convert Blob to ArrayBuffer for upload to S3
      const arrayBuffer = await blob.arrayBuffer();

      // Upload the HTML file to S3
      await UploadVersionToS3HTML(newPutVersionPreSignedUrl, arrayBuffer);

      console.log("File uploaded successfully.");

      // Fetch pre-signed URL for retrieving the uploaded HTML file
      const newGetVersionPreSignedUrl = await fetchNewVersionPreSignedUrl(
        versionName,
        "getObject"
      );

      return newGetVersionPreSignedUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const fetchNewVersionPreSignedUrl = async (versionName, action) => {
    if (versionName === "") return;

    const newVersionS3Key = `${project.file_path}/${versionName}`;

    try {
      const url = await FetchPreSignedUrl(newVersionS3Key, action); // Assuming 'upload' is the action

      return url;
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const handleCreateVersion = async () => {
    setLoading(true);
    let newVersionNameFileName = `${newVersionName}`;
    let newVersionNameDocumentName = `${newVersionNameFileName}.html`;

    versionsList.map((version) => {
      if (version.file_name === newVersionName) {
        const newVersionNameDuplicate = `${newVersionName}(${versionsList.length})`;
        newVersionNameFileName = `${newVersionNameDuplicate}`;
        newVersionNameDocumentName = `${newVersionNameFileName}.html`;
      }
    });

    const versionPreSignedUrl = await generateAndUploadHtml(
      htmlContent,
      newVersionNameDocumentName
    );

    if (versionPreSignedUrl) {
      let s3Key = `${project.file_path}/${newVersionNameDocumentName}`;

      const documentVersionId = await CreateProjectVersion(
        user.email,
        newVersionNameFileName,
        project,
        newVersionNameDocumentName
      );

      await StartDocumentVersionProcessing(
        process.env.REACT_APP_PLAINLANGUAGE_S3_BUCKET,
        s3Key,
        versionPreSignedUrl,
        project.id,
        documentVersionId
      )
        .then((metrics) => {
          console.log("Document version processing started");
          UpdateDocumentVersion(metrics, documentVersionId);
          showToast(
            "Project Version Created and is now Processing",
            "success",
            5000
          );
        })
        .catch((err) => {
          console.error(`Error extracting and processing file: ${err}`);
        });
      setIsVersionPolling(true);
    }

    setLoading(false);
    setNewVersionName("");
  };

  return (
    <Box>
      <Tooltip
        background={theme.colors.gray}
        color={theme.colors.text}
        label={
          <>
            <b>Create Version:</b> This feature will allow you to save a new
            version of your document with any changes you have made in the
            editor. It will take the text directly from the editor and run it
            through the plain language processor to generate new recommendations
            and FedWriters scores! Accept or ignore the recommendations as you
            see fit, and then save the new version to your project.
            <br />
            <br />
            <b>Versions:</b> This is your list of versions with the name, date
            created, and status of the version as it is processed through our
            plain language processor!
          </>
        }
        aria-label="Basic Metrics tooltip"
      >
        <Button
          style={{
            color: "white",
            backgroundColor: "#4A5568",
            marginRight: "10px",
            width: "150px",
          }}
          isDisabled={loading || editorLoadingState}
          onClick={onOpen}
        >
          {loading ? <Spinner /> : "Create Version"}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter New Version Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="New Version Name"
              value={newVersionName}
              onChange={(e) => setNewVersionName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose();
                handleCreateVersion();
              }}
              isDisabled={loading || !newVersionName}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
