import { useState, useEffect } from "react";
import airForceLogo from "../../../../assets/air-force-logo.png";
import armyCyberLogo from "../../../../assets/army-cyber-logo.png";
import depOfEducationLogo from "../../../../assets/dep-of-education-logo.png";
import depOfEnergyLogo from "../../../../assets/dep-of-energy-logo.png";
import depOfInteriorLogo from "../../../../assets/dep-of-interior-logo.png";
import depOfTreasuryLogo from "../../../../assets/department-of-treasury-logo.png";
import foodDrugAdminLogo from "../../../../assets/Food_and_Drug_Administration_logo-1.png";
import futuresTradingCommLogo from "../../../../assets/futures-trading-commission-logo.png";
import humanServLogo from "../../../../assets/human-serv-logo.png";
import inspectorGenLogo from "../../../../assets/inspector-general-logo.png";
import libOfCongressLogo from "../../../../assets/library-of-congress-logo.png";
import nasaLogo from "../../../../assets/NASA_logo-1.png";
import noaaLogo from "../../../../assets/NOAA_logo-1.png";
import nsfLogo from "../../../../assets/nsf-logo.png";
import sealOfHomelandSecLogo from "../../../../assets/Seal_of_the_United_States_Department_of_Homeland_Security-1.png";
import spaceForceLogo from "../../../../assets/space-force-logo.png";
import usCoastGuardLogo from "../../../../assets/us-coast-guard-logo.png";
import usDefenseThreatRedAgencyLogo from "../../../../assets/US-DefenseThreatReductionAgency-Seal-2.png";
import usNationalParkServLogo from "../../../../assets/US-NationalParkService-Logo-1.png";
import * as S from "./styles";

export const TilesComponent = () => {
  const logos = [
    depOfTreasuryLogo,
    spaceForceLogo,
    nsfLogo,
    armyCyberLogo,
    humanServLogo,
    depOfEnergyLogo,
    usNationalParkServLogo,
    foodDrugAdminLogo,
    usDefenseThreatRedAgencyLogo,
    noaaLogo,
    nasaLogo,
    airForceLogo,
    sealOfHomelandSecLogo,
    usCoastGuardLogo,
    futuresTradingCommLogo,
    depOfEducationLogo,
    libOfCongressLogo,
    inspectorGenLogo,
    depOfInteriorLogo,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 3000);
    return () => clearInterval(interval);
  });

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < logos.length ? prevIndex + 1 : 0
    );
  };

  return (
    <S.StyledFlexTileContainer>
      <S.StyledTilesHeading>
        Proudly serving top government agencies
      </S.StyledTilesHeading>
      <S.StyledLogoContainer>
        {/* Left edge of the "box wall" */}
        <S.StyledWhiteSpaceBox left={0} />
        {/* Right edge of the "box wall" */}
        <S.StyledWhiteSpaceBox right={0} />
        {logos.map((logo, index) => (
          <S.StyledIndividualLogoContainer
            index={index}
            logosLength={logos.length}
            currIndex={currentIndex}
            key={index}
          >
            {index === currentIndex - 1 ||
            (currentIndex === 0 && index === logos.length - 1) ? null : (
              <S.StyledImage index={index} logo={logo} key={index} />
            )}
          </S.StyledIndividualLogoContainer>
        ))}
      </S.StyledLogoContainer>
    </S.StyledFlexTileContainer>
  );
};
