import { Grid, Heading, Text, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledFeaturesColumnContainer = ({ children }) => {
  return (
    <Box
      pt={28}
      pl={{ base: 5, sm: 20 }}
      pb={16}
      pr={{ base: 5, sm: 20 }}
      display={"flex"}
    >
      {children}
    </Box>
  );
};

export const StyledFeaturesHeadingSmall = ({ children }) => {
  return (
    <Heading fontSize={"md"} pb={"4"}>
      {children}
    </Heading>
  );
};

export const StyledFeaturesHeadingBig = ({ children }) => {
  return (
    <Heading fontSize="6xl" pb={"10"}>
      {children}
    </Heading>
  );
};

export const StyledGridContainer = ({ children }) => {
  return (
    <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
      {children}
    </Grid>
  );
};

export const StyledIconBoxContainer = ({ children, index, bc }) => {
  return (
    <Box key={index} p={16} border={"1px"} borderColor={bc}>
      {children}
    </Box>
  );
};

export const StyledIconBackgroundBox = ({ children, bg }) => {
  return (
    <Box
      bg={bg}
      borderRadius="full"
      width="45px"
      height="45px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="1"
    >
      {children}
    </Box>
  );
};

export const StyledIcon = ({ icon, color }) => {
  return <FontAwesomeIcon icon={icon} color={color} fontSize={"20px"} />;
};

export const StyledItemHeading = ({ children }) => {
  return (
    <Heading pt={6} fontSize={{ base: "xl" }}>
      {children}
    </Heading>
  );
};

export const StyledItemText = ({ children }) => {
  return <Text pt={2}>{children}</Text>;
};

export const StyledSubtitleText = ({ children }) => {
  return <Text fontSize="2xl">{children}</Text>;
};
