import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Heading,
  Tooltip,
  Icon,
  Spinner,
  Image,
  useTheme,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { callFileExtractor } from "../../api/callFileExtractor";
import {
  faNewspaper,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileEditor } from "../../editor/";
import {
  getAgencyIcons,
  getPlaceHolderIcon,
} from "../../components/agencyIconsComponent/index";

const placeholderIcon = getPlaceHolderIcon();

function getAgencyIcon(logoName) {
  if (!logoName) return placeholderIcon;
  const imagePath = logoName.logo;
  return imagePath;
}

const MainContent = ({
  user,
  selectedProject,
  textFileUrl,
  textContent,
  handleSidebarClick,
  isProjectSelected,
  selectedProjectVersion,
  setSelectedProjectVersion,
  isProjectVersionSelected,
  setIsProjectVersionSelected,
}) => {
  const [loading, setLoading] = useState(false);
  const [agencyToLogoMapping, setAgencyToLogoMapping] = useState({});
  const [s3FileKey, setS3FileKey] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const map = getAgencyIcons();
    setAgencyToLogoMapping(map);
  }, []);

  useEffect(() => {
    if (isProjectVersionSelected) {
      setS3FileKey(
        `${selectedProjectVersion && selectedProjectVersion.file_path}/${
          selectedProjectVersion && selectedProjectVersion.document_name
        }`
      );
    } else {
      setS3FileKey(
        `${selectedProject && selectedProject.file_path}/${
          selectedProject && selectedProject.document_name
        }`
      );
    }
  }, [selectedProject, selectedProjectVersion]);

  const getSelectedAgencyLogo = () => {
    if (
      selectedProject &&
      selectedProject.agency &&
      selectedProject.agency[0]
    ) {
      return getAgencyIcon(agencyToLogoMapping[selectedProject.agency[0]]);
    }
    return placeholderIcon;
  };

  const handleRescoreDoc = async (project, signedUrl) => {
    try {
      const response = await callFileExtractor(
        process.env.REACT_APP_PLAINLANGUAGE_S3_BUCKET,
        project.file_path,
        signedUrl
      );
      console.log("Rescore: ", response);
    } catch (error) {
      console.error("Error rescoring doc:", error);
    }
  };

  const getBackgroundColor = (daleChallScore) => {
    const renderedDaleChallScore =
      isProjectVersionSelected && selectedProjectVersion.dale_chall
        ? selectedProjectVersion.dale_chall
        : daleChallScore;
    if (renderedDaleChallScore > 11) {
      return "red.200"; // light red
    } else if (renderedDaleChallScore >= 8.6 && renderedDaleChallScore <= 11) {
      return "yellow.200";
    } else {
      return "green.200";
    }
  };

  const getParsingMetricsBackgroundColor = (mlwfWithStop, mlwfWithoutStop) => {
    const renderedCompositeScore =
      isProjectVersionSelected &&
      selectedProjectVersion.mlwf_with_stop &&
      selectedProjectVersion.mlwf_without_stop
        ? 0.6 * selectedProjectVersion.mlwf_with_stop +
          0.4 * selectedProjectVersion.mlwf_without_stop
        : 0.6 * mlwfWithoutStop + 0.4 * mlwfWithStop;

    if (renderedCompositeScore >= 4.5) {
      return "green.200";
    } else if (renderedCompositeScore >= 3.5) {
      return "yellow.200";
    } else {
      return "red.200";
    }
  };

  const getBERTBackgroundColor = (bertBad, bertTotal) => {
    const badPercentage = (bertBad / bertTotal) * 100;

    if (badPercentage > 50) {
      return "red.200"; // light red
    } else if (badPercentage < 20) {
      return "green.200";
    } else {
      return "yellow.200";
    }
  };

  return (
    <>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 1.2)"
          zIndex="1000"
        >
          <Spinner size="xl" color="red" />
          <Text ml={4}>Loading File Contents...</Text>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        h="100vh"
        overflowY="auto"
        backgroundColor="#f7fafc"
      >
        {selectedProject ? (
          <>
            {isProjectVersionSelected && (
              <Flex
                width={"100%"}
                mt={2}
                position={"fixed"}
                paddingRight={10}
                alignContent={"center"}
                justifyContent={"flex-end"}
              >
                <Heading as="h1" size="md" color="#2D3748">
                  Current Project:
                </Heading>
                <Text fontSize={"md"} paddingLeft={2} color="#2D3748">
                  {selectedProject.file_name}
                </Text>
              </Flex>
            )}
            <Flex justifyContent="center" alignContent="center" mt={4} mb={4}>
              <Heading as="h1" size="2xl" color="#2D3748">
                {isProjectVersionSelected && selectedProjectVersion.file_name
                  ? selectedProjectVersion.file_name
                  : selectedProject.file_name}
              </Heading>
            </Flex>

            <Flex flexDirection="row" width="100%">
              {/* Left third: Metrics */}
              <Flex
                flexDirection="column"
                width="15%"
                alignItems="stretch"
                mt={4}
                ml={6}
                mr={6}
              >
                {/* Basic Metrics */}
                {/*<IconButton
                                    as="a"
                                    ml={4}
                                    mb={1}
                                    href={textFileUrl}
                                    download="myFile.txt"
                                    colorScheme="teal"
                                    icon={<DownloadIcon />}
                                />
                                <Button ml={4} mb={2} colorScheme="purple" isDisabled={true}>Rescore Document</Button>*/}
                <Box
                  position="relative"
                  p={5}
                  ml={4}
                  mb={1}
                  shadow="md"
                  borderWidth="1px"
                  borderRadius="md"
                  h="150px"
                  overflow="auto"
                >
                  <Box
                    position="absolute"
                    left="0"
                    top="0"
                    bottom="0"
                    width="8px"
                    backgroundColor={getBackgroundColor(
                      selectedProject.dale_chall
                    )}
                  />
                  <Flex align="center">
                    <Heading fontSize="xl">FedWriters Scores</Heading>
                    <Tooltip
                      background={theme.colors.gray}
                      color={theme.colors.text}
                      label={
                        <>
                          <b>Reading Ease Score:</b> This tool assesses your
                          text by looking at the length of your sentences and
                          the number of syllables in your words. Short sentences
                          and simpler words get a higher score, meaning your
                          text is easier to read. It's an excellent way to
                          ensure your content is clear, concise, and
                          reader-friendly!
                          <br />
                          <br />
                          <b>Grade Level Score:</b> This feature examines the
                          text you provide and rates its complexity based on a
                          list of 3,000 words that most 4th-grade students
                          should understand. If your text uses more words not on
                          this list, it's considered harder to read and gets a
                          higher score. This tool is great for ensuring your
                          content is accessible to your intended readership!
                        </>
                      }
                      aria-label="Basic Metrics tooltip"
                    >
                      <Icon boxSize={5} ml={2} as={InfoOutlineIcon} />
                    </Tooltip>
                  </Flex>
                  <Flex align="center">
                    <FontAwesomeIcon
                      icon={faNewspaper}
                      style={{ marginRight: "8px" }}
                    />
                    <Text>
                      Readability:{" "}
                      {isProjectVersionSelected && selectedProjectVersion.flesch
                        ? selectedProjectVersion.flesch
                        : selectedProject.flesch}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      style={{ marginRight: "8px" }}
                    />
                    <Text>
                      Grade Level:{" "}
                      {isProjectVersionSelected &&
                      selectedProjectVersion.dale_chall
                        ? selectedProjectVersion.dale_chall
                        : selectedProject.dale_chall}
                    </Text>
                  </Flex>
                  {/*
                                    <Flex align="center">
                                        <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '8px' }} />
                                        <Text>MLWF: {selectedProject.mlwf}</Text>
                                    </Flex>
                                        */}
                </Box>
                {/* Parsing Metrics */}
                <Box
                  position="relative"
                  p={5}
                  ml={4}
                  mb={1}
                  shadow="md"
                  borderWidth="1px"
                  borderRadius="md"
                  h="150px"
                  overflow="auto"
                >
                  <Box
                    position="absolute"
                    left="0"
                    top="0"
                    bottom="0"
                    width="8px"
                    backgroundColor={getParsingMetricsBackgroundColor(
                      selectedProject.mlwf_with_stop,
                      selectedProject.mlwf_without_stop
                    )}
                  />
                  <Flex align="center">
                    <Heading fontSize="xl">Parsing Metrics</Heading>
                    <Tooltip
                      background={theme.colors.gray}
                      color={theme.colors.text}
                      label={
                        <>
                          <b>Mean Word Log Frequency (with Stop Words):</b> This
                          tool calculates the average frequency of all the words
                          in your text, including common words like 'and',
                          'the', 'is', etc., using a statistical count. Higher
                          scores indicate your text is filled with more commonly
                          used words, making it more easily readable.
                          <br />
                          <br />
                          <b>
                            Mean Word Log Frequency (without Stop Words):
                          </b>{" "}
                          This does the same as above, but ignores common words
                          (stop words). It focuses more on the frequency of your
                          'content' words, helping you gauge if your text is
                          filled with common or uncommon words, contributing to
                          its readability.
                        </>
                      }
                      aria-label="Parsing Metrics tooltip"
                    >
                      <Icon boxSize={5} ml={2} as={InfoOutlineIcon} />
                    </Tooltip>
                  </Flex>
                  <Text>
                    MLWF with stopwords:{" "}
                    {isProjectVersionSelected &&
                    selectedProjectVersion.mlwf_with_stop
                      ? selectedProjectVersion.mlwf_with_stop
                      : selectedProject.mlwf_with_stop}
                  </Text>
                  <Text>
                    MLWF without stopwords:{" "}
                    {isProjectVersionSelected &&
                    selectedProjectVersion.mlwf_without_stop
                      ? selectedProjectVersion.mlwf_without_stop
                      : selectedProject.mlwf_without_stop}
                  </Text>
                </Box>
                {/* BERT Classification */}
                {/* <Box
                  position="relative"
                  p={5}
                  ml={4}
                  mb={1}
                  shadow="md"
                  borderWidth="1px"
                  borderRadius="md"
                  h="150px"
                  overflow="auto"
                >
                  <Box
                    position="absolute"
                    left="0"
                    top="0"
                    bottom="0"
                    width="8px"
                    backgroundColor={getBERTBackgroundColor(
                      selectedProject.bert_bad,
                      selectedProject.bert_total
                    )}
                  />
                  <Flex align="center">
                    <Heading fontSize="lg">BERT Classification</Heading>
                    <Tooltip
                      background={theme.colors.gray}
                      color={theme.colors.text}
                      sx={{ maxWidth: "400px" }} // Adjust the width as needed
                      label={
                        <>
                          <b>BERT Classification:</b> This tool uses the BERT
                          model to evaluate the quality of your text. BERT
                          (Bidirectional Encoder Representations from
                          Transformers) is a deep learning model developed by
                          Google for natural language processing tasks. It is
                          designed to understand the context of words in a
                          sentence by reading text in both directions
                          (left-to-right and right-to-left), which enhances its
                          ability to interpret the meaning and quality of
                          sentences.
                          <br />
                          <br />
                          <b>Total Sentences:</b> Represents the total number of
                          sentences in your text. This gives an overview of the
                          text's structure and length.
                          <br />
                          <br />
                          <b>Good Sentences:</b> The number of sentences
                          classified as 'Good' by the BERT model. These
                          sentences are identified as clear, well-structured,
                          and effective, contributing positively to the
                          readability and overall quality of your text.
                          <br />
                          <br />
                          <b>Bad Sentences:</b> The number of sentences
                          classified as 'Bad' by the BERT model. These sentences
                          may be flagged as complex, unclear, or potentially
                          problematic, suggesting areas where the text may need
                          improvement.
                          <br />
                        </>
                      }
                      aria-label="BERT Classification tooltip"
                    >
                      <Icon boxSize={5} ml={2} as={InfoOutlineIcon} />
                    </Tooltip>
                  </Flex>
                  <Text>Total sentences: {selectedProject.bert_total}</Text>
                  <Text>Good sentences: {selectedProject.bert_good}</Text>
                  <Text>Bad sentences: {selectedProject.bert_bad}</Text>
                </Box> */}

                {/* Style Guide(s) */}
                <Box
                  position="relative"
                  p={5}
                  ml={4}
                  mb={1}
                  shadow="md"
                  borderWidth="1px"
                  borderRadius="md"
                  h="200px"
                  overflow="auto"
                  opacity="0.5" // Lower the opacity to give a greyed-out effect
                  pointerEvents="none" // Disable pointer events
                  style={{ background: "#f0f0f0" }} // Optional: Change background color to enhance the disabled look
                >
                  <Heading fontSize="xl">Reference Style Guide(s)</Heading>
                  {/* <Image
                    src={getSelectedAgencyLogo()}
                    alt="Agency Logo"
                    mt={2}
                    boxSize="100px"
                    objectFit="contain"
                  /> */}
                </Box>
              </Flex>

              {/* Right two thirds: File Editor */}
              <Flex
                flexDirection="row"
                flex="1"
                maxHeight="calc(100vh - 200px)"
                backgroundColor="#edf2f7"
                borderRadius="md"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  if (!isProjectSelected) {
                    handleSidebarClick();
                  }
                }}
              >
                <FileEditor
                  user={user}
                  s3Key={s3FileKey}
                  selectedProject={selectedProject}
                  textContent={textContent}
                  setLoading={setLoading}
                  selectedProjectVersion={selectedProjectVersion}
                  setSelectedProjectVersion={setSelectedProjectVersion}
                  isProjectVersionSelected={isProjectVersionSelected}
                  setIsProjectVersionSelected={setIsProjectVersionSelected}
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex justifyContent="center" mt={12}>
            <Heading as="h2" size="xl">
              Select a project from the sidebar or create a new one
            </Heading>
          </Flex>
        )}
      </Flex>
    </>
  );
};
export default MainContent;
