import axios from "axios";

export const FetchPreSignedUrl = async (s3Key, action) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_GENERATE_PRESIGNED_URL_API,
      {
        key: s3Key,
        action: action,
      }
    );
    const url = JSON.parse(response.data.body).url;
    return url;
  } catch (error) {
    console.error("Error fetching pre-signed URL:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const UploadVersionToS3HTML = async (url, data) => {
  try {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": "text/html", // Updated MIME type for HTML files
      },
    });
  } catch (error) {
    console.error("Error uploading to S3:", error);
  }
};

export const ConvertHtmlToDocx = async (bucketName, s3Key) => {
  try {
    const params = {
      Records: [
        {
          s3: {
            bucket: {
              name: bucketName,
            },
            object: {
              key: s3Key,
            },
          },
        },
      ],
    };

    const response = await axios.post(
      process.env.REACT_APP_CONVERT_HTML_TO_DOCX_API_URL,
      params
    );

    return response;
  } catch (error) {
    console.error("Error converting HTML to Docx File:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
