import { Flex, useTheme, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScaleBalanced,
  faEarthAmericas,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";

export const FeaturesHeadlineComponent = () => {
  const theme = useTheme();
  const { primary, secondary, accentOne } = theme.colors;
  const { heading } = theme.fonts;

  const headlinesItems = [
    {
      icon: faScaleBalanced,
      heading: "Plain Language Law Compliance: ",
      subtitle:
        "Ensures communications are clear and accessible as mandated by federal law, enhancing public understanding.",
    },
    {
      icon: faEarthAmericas,
      heading: "Enhanced Public Engagement: ",
      subtitle:
        "Promotes transparency and efficiency in government communication, making information easily understandable on the first reading.",
    },
    {
      icon: faClipboardList,
      heading: "Legal Adherence and Simplification: ",
      subtitle:
        "Supports federal agencies in meeting legal requirements for simplicity in public documents and communications.",
    },
  ];

  return (
    <S.StyledHeroContainer bgColor={secondary}>
      <Flex display={{ base: "column", md: "flex" }}>
        <S.StyledHeadingFlexBoxContainer>
          <S.StyledHeadlineHeading>
            Commitment to clarity
          </S.StyledHeadlineHeading>
        </S.StyledHeadingFlexBoxContainer>
        <S.StyledHeadlinesFlexBoxContainer>
          <S.StyledGridContainer>
            {headlinesItems.map((item, index) => (
              <Flex pl={6} key={index}>
                <S.StyledIconBoxContainer index={index}>
                  <S.StyledIcon
                    icon={item.icon}
                    color={theme.colors.accentOne}
                  />
                </S.StyledIconBoxContainer>
                <Box>
                  <S.StyledItemHeading>{item.heading}</S.StyledItemHeading>
                  <S.StyledItemText>{item.subtitle}</S.StyledItemText>
                </Box>
              </Flex>
            ))}
          </S.StyledGridContainer>
        </S.StyledHeadlinesFlexBoxContainer>
      </Flex>
    </S.StyledHeroContainer>
  );
};
