import { React, useState } from "react";
import { Button, Box, Spinner, Tooltip, useTheme } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { FetchPreSignedUrl, ConvertHtmlToDocx } from "../../../api/s3";
import useCustomToast from "../../../hooks/useCustomToast";

export const DownloadVersionComponent = ({ s3Key, selectedProjectVersion }) => {
  const showToast = useCustomToast();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    setLoading(true);

    const bucket = process.env.REACT_APP_PLAINLANGUAGE_S3_BUCKET;

    const response = await ConvertHtmlToDocx(bucket, s3Key);

    if (response.status === 200) {
      if (response.data.body.docx_key) {
        const docxKey = response.data.body.docx_key;

        // Generate the S3 URL
        const s3Url = await FetchPreSignedUrl(docxKey, "getObject");

        if (s3Url === null) {
          showToast("Error fetching the document", "error", 5000);
          setError("Error fetching the s3 key for the document");
        } else {
          // Create an anchor element and trigger a download
          const link = document.createElement("a");
          link.href = s3Url;
          link.download = docxKey.split("/").pop(); // Use the file name from the key
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showToast("Downloaded successfully!", "success", 5000);
        }
      } else if (response.data.body.error) {
        setError(
          "Error processing download: " + response.data.body.error.message
        );
        showToast("Error processing download", "error", 5000);
      }
    } else {
      setError("Error processing download");
      showToast("Error processing download", "error", 5000);
    }

    setLoading(false);
  };

  return (
    <Box>
      <Tooltip
        background={theme.colors.gray}
        color={theme.colors.text}
        label={
          <>
            <b>Download Version:</b> Download the current version you have
            selected to your local machine.
            <br />
            <br />
            <b>DISCLAIMER:</b> Document formatting can be different than the
            original project document. By using this feature, you agree to this
            risk.
          </>
        }
        aria-label="Basic Metrics tooltip"
      >
        <Button
          style={{
            color: "white",
            backgroundColor: "#4A5568",
            width: "50px",
          }}
          isDisabled={loading}
          onClick={handleDownload}
        >
          {loading ? <Spinner size="sm" /> : <DownloadIcon fontSize={"2xl"} />}
        </Button>
      </Tooltip>
    </Box>
  );
};
