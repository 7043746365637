import { Box, Heading, Text, Grid } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledHeroContainer = ({ children, bgColor }) => {
  return (
    <Box
      bg={bgColor}
      pt={{ base: "32" }}
      pl={{ base: "5", sm: "20" }}
      pr={{ base: "5", sm: "20" }}
      pb={{ base: "32" }}
      borderRadius="md"
    >
      {children}
    </Box>
  );
};

export const StyledHeadingFlexBoxContainer = ({ children }) => {
  return (
    <Box flex="0.8" display="flex" alignItems="center">
      {children}
    </Box>
  );
};

export const StyledHeadlinesFlexBoxContainer = ({ children }) => {
  return <Box flex="1">{children}</Box>;
};

export const StyledHeadlineHeading = ({ children }) => {
  return (
    <Heading as="h1" size={{ base: "2xl", md: "4xl" }} mb={6}>
      {children}
    </Heading>
  );
};

export const StyledSubtitleText = ({ children }) => {
  return (
    <Text fontSize="md" mb={6} whiteSpace="pre-wrap">
      {children}
    </Text>
  );
};

export const StyledGridContainer = ({ children }) => {
  return (
    <Grid templateColumns={{ base: "1fr" }} gap={6}>
      {children}
    </Grid>
  );
};

export const StyledItemHeading = ({ children }) => {
  return (
    <Heading pt={6} fontSize={{ base: "xl" }}>
      {children}
    </Heading>
  );
};

export const StyledIconBoxContainer = ({ children, index }) => {
  return (
    <Box key={index} display="flex" alignItems="center" pr="6">
      {children}
    </Box>
  );
};

export const StyledIcon = ({ icon, color }) => {
  return <FontAwesomeIcon icon={icon} color={color} fontSize="42px" />;
};

export const StyledItemText = ({ children }) => {
  return <Text pt={2}>{children}</Text>;
};
