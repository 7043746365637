import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Image,
  MenuDivider,
} from "@chakra-ui/react";
import plainLanguageProWhite from "../../assets/PlainLanguageProWhite.png";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { signOut } from "../../api/user";

export const Header = ({ user, isSticky }) => {
  const auth = useAuth();

  const bgColor = useColorModeValue("black", "black");

  let stickyProps = {};
  if (isSticky) {
    stickyProps = {
      position: "sticky",
      top: 0,
      zIndex: 1,
    };
  }

  const handleSignOut = async () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      await signOut();
      auth.logout();
      window.location.reload();
    }
  };

  return (
    <Box
      py="2"
      pr="4"
      pl="2"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      w="full"
      height="64px"
      bg={bgColor}
      borderBottom="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      {...stickyProps}
    >
      <Link to="/home">
        <Image
          boxSize="180px"
          fit="contain"
          src={plainLanguageProWhite}
          alt="PlainLanguagePro Logo"
        />
      </Link>
      <Menu>
        <MenuButton
          as={Box}
          aria-label="User profile menu"
          variant="ghost"
          p={2}
          _hover={{
            bg: "gray.500",
          }}
          borderRadius="xl"
        >
          <Avatar
            size="md"
            name={user && `${user.firstName} ${user.lastName}`}
            src="" // Replace this with the actual avatar URL if available
          />
        </MenuButton>
        <MenuList>
          <MenuItem>
            <HStack>
              <Avatar
                size="sm"
                name={user && `${user.firstName} ${user.lastName}`}
                src="" // Replace this with the actual avatar URL if available
              />
              <Text>{user && user.email}</Text>
            </HStack>
          </MenuItem>
          <MenuItem as={Link} to="/profile">
            View Profile
          </MenuItem>
          <MenuDivider />
          <MenuItem
            as={Link}
            to="https://www.plainlanguage.gov/law/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Plain Language Law
          </MenuItem>
          <MenuItem as={Link} to="/style-guides">
            Style Guides
          </MenuItem>
          <MenuItem as={Link} to="/support">
            Get Support
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Header;
