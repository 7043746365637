import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./auth";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "../customTheme";

const AppProvider = ({ children }) => (
  <Router>
    <AuthProvider>
      <ChakraProvider theme={customTheme}>{children}</ChakraProvider>
    </AuthProvider>
  </Router>
);

export default AppProvider;
