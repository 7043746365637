import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Image, Input, Text, Spinner } from "@chakra-ui/react";
import useCustomToast from "../../hooks/useCustomToast";
import axios from "axios";

export const deleteFile = async (userFolderId, projectName, fileName) => {
  try {
    const response = await axios.post(process.env.REACT_APP_DELETE_FILE_API, {
      userFolderId,
      projectName,
      fileName,
    });
    console.log("File deleted successfully:", response.data);
    return response.data;
  } catch (err) {
    console.error("Error deleting file:", err);
    throw err; // Re-throw the error to be handled in the .catch block of handleClose
  }
};

export function DocumentUploader({
  userFolderId,
  projectName,
  onUpload,
  onUploadFileName,
  isDisabled,
}) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const showToast = useCustomToast();

  useEffect(() => {
    const fetchPreSignedUrl = async (action) => {
      const s3Key = `${userFolderId}/${projectName}/${file.name}`;
      try {
        const response = await axios.post(
          process.env.REACT_APP_GENERATE_PRESIGNED_URL_API,
          {
            key: s3Key,
            action: action,
          }
        );
        const preSignedUrl = JSON.parse(response.data.body).url;
        return preSignedUrl;
      } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
      }
    };

    const uploadToS3 = async (url) => {
      try {
        return await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      } catch (error) {
        console.error("Error uploading to S3:", error);
      }
    };

    const uploadDocument = async () => {
      const s3Key = `${userFolderId}/${projectName}/${file.name}`;

      if (userFolderId && projectName && file) {
        setIsUploading(true);
        const uploadUrl = await fetchPreSignedUrl("putObject");
        if (uploadUrl) {
          const uploadResponse = await uploadToS3(uploadUrl);
          if (uploadResponse.status === 200) {
            const extension = file.name.split(".").pop();

            // Fetch the pre-signed URL for preview
            const previewUrl = await fetchPreSignedUrl("getObject");
            if (previewUrl) {
              onUpload({ url: previewUrl, extension });
              setFile(null);
              setPreview(previewUrl); // Set preview to the download URL
              setFileExtension(extension);
              onUploadFileName(file.name);
              setIsUploading(false);
            }
          }
        }
      }
    };

    if (file) {
      uploadDocument();
    }
  }, [userFolderId, projectName, file, onUpload, onUploadFileName]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (isDisabled) return;
      const file = acceptedFiles[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      // Temporarily disable PDF uploads until PDF functionality if fully implemented correctly
      if (fileExtension != "docx") {
        showToast(
          "Unsupported file type. Only .docx files are allowed.",
          "warning",
          5000
        );
        setFile(null);
        setPreview(null);
        return;
      }

      localStorage.setItem("fileName", file.name);
      setFile(file);
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    },
    [isDisabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const dropzoneStyle = {
    borderStyle: "dashed",
    borderColor: isDisabled ? "gray.300" : "gray.500", // Gray out the border if disabled
    textAlign: "center",
    mt: 4,
    backgroundColor: isDisabled ? "#f0f0f0" : "", // Light background for disabled state
    opacity: isDisabled ? 0.6 : 1, // Reduced opacity for disabled state
    pointerEvents: isDisabled ? "none" : "auto", // Prevent all interactions if disabled
  };

  return (
    <Box
      {...getRootProps()}
      p={6}
      borderWidth={2}
      borderRadius="md"
      style={dropzoneStyle}
    >
      <Input {...getInputProps()} display="none" />
      {isDragActive ? (
        <Text>Drop the files here ...</Text>
      ) : (
        <Text>Drag 'n' drop some files here, or click to select files</Text>
      )}
      {file && (
        <Box>
          <Text as="strong">Selected file:</Text> {file.path}
        </Box>
      )}
      {isUploading ? (
        <Spinner />
      ) : (
        preview &&
        fileExtension !== "pdf" && <Image src={preview} alt="preview" />
      )}
    </Box>
  );
}
