import axios from "axios";

export const callFileExtractor = async (bucket, key, signedUrl, docId) => {
  const api = process.env.REACT_APP_PLAINLANGUAGE_METRICS_API_URL;
  const params = {
    Records: [
      {
        s3: {
          bucket: {
            name: bucket,
          },
          object: {
            key: key,
            url: signedUrl,
            documentId: docId,
            documentVersionId: null,
          },
        },
      },
    ],
  };

  try {
    const response = await axios.post(api, params);
    const responseBody = JSON.parse(response.data.body);
    const uniqueKey = responseBody.unique_key;
    return uniqueKey;
  } catch (error) {
    console.error(`Error calling RFPExtractor in callFileExtractor:`, error);
  }
};
