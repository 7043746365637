import { useToast } from "@chakra-ui/react";

const useCustomToast = () => {
  const toast = useToast();

  const showToast = (message, status = "info", duration = 3000) => {
    toast({
      title: message,
      position: "top-middle",
      status: status,
      duration: duration,
      isClosable: true,
    });
  };

  return showToast;
};

export default useCustomToast;
