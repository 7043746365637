import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#FFFFFF",
    secondary: "#FAF8F2",
    accentOne: "#3F76A3",
    accentTwo: "#EBF3F7",
    text: "#121212",
    gray: "#D9D9D9",
  },
  fonts: {
    body: "IBM Plex Serif",
    heading: "Roboto",
  },
  // Add more custom theme configurations here
});

export default theme;
