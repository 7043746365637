import React, { createContext, useState, useEffect, useContext } from "react";
import { Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie, deleteCookie } from "../utils/cookies";
import { isLoggedIn } from "../api/user";
import * as routes from "../constants/routes";

// Configure Amplify with Cognito details
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: "us-east-2",
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

export const AuthContext = createContext({});

export function AuthProvider(props) {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isObfuscationPassed, setIsObfuscationPassed] = useState(
    localStorage.getItem("isObfuscationPassed") === "true"
  );

  useEffect(() => {
    const checkLoggedIn = async () => {
      const user = await isLoggedIn();
      if (
        user.isAuthenticated === true &&
        isObfuscationPassed &&
        getCookie("isAuthenticated")
      ) {
        setAuthUser(user);
        setIsAuthenticated(true);
      } else {
        setAuthUser(null);
        setIsAuthenticated(false);
        navigate(routes.LANDING);
      }
    };

    checkLoggedIn();
  }, []);

  useEffect(() => {
    if (getCookie("isAuthenticated") && authUser && isObfuscationPassed) {
      setAuthUser(authUser);
      if (authUser.isAuthenticated) {
        setIsAuthenticated(true);
      }
    }
  }, [authUser]);

  const login = (user) => {
    setAuthUser(user);
    setCookie("isAuthenticated", true);
  };
  const logout = () => {
    setAuthUser(null);
    deleteCookie("isAuthenticated");
  };

  const obfuscated = (isPassed) => {
    setIsObfuscationPassed(isPassed);
    localStorage.setItem("isObfuscationPassed", isPassed);
  };

  const value = {
    user: authUser,
    login,
    logout,
    obfuscated,
    isObfuscationPassed,
    isAuthenticated,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
