import { useTheme } from "@chakra-ui/react";
import {
  faBuildingShield,
  faUserGroup,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";

export const WhyUsComponent = () => {
  const theme = useTheme();

  const whyUsItems = [
    {
      icon: faBuildingShield,
      heading: "Agency trusted",
      subtitle:
        "Join numerous federal agencies enhancing their communication with PlainLanguagePro.",
    },
    {
      icon: faUserGroup,
      heading: "Improved engagement",
      subtitle:
        "Clear communication improves accessible and leads to better public understanding and engagement.",
    },
    {
      icon: faClock,
      heading: "Save time and resources",
      subtitle:
        "Reduce the need for extensive revisions and ensure compliance with legal standards efficiently.",
    },
  ];

  return (
    <S.StyledFeaturesColumnContainer>
      <S.StyledFeaturesHeadingSmall>Why Us</S.StyledFeaturesHeadingSmall>
      <S.StyledFeaturesHeadingBig>Why choose us?</S.StyledFeaturesHeadingBig>
      <S.StyledGridContainer>
        {whyUsItems.map((item, index) => (
          <S.StyledIconBoxContainer
            index={index}
            key={index}
            bc={theme.colors.gray}
          >
            <S.StyledIconBackgroundBox bg={theme.colors.accentTwo}>
              <S.StyledIcon icon={item.icon} color={theme.colors.accentOne} />
            </S.StyledIconBackgroundBox>
            <S.StyledItemHeading>{item.heading}</S.StyledItemHeading>
            <S.StyledItemText>{item.subtitle}</S.StyledItemText>
          </S.StyledIconBoxContainer>
        ))}
      </S.StyledGridContainer>
    </S.StyledFeaturesColumnContainer>
  );
};
