import React from "react";
import { Button } from "@chakra-ui/react";

export const ButtonComponent = ({
  width,
  children,
  onClick,
  bgColor,
  isDisabled,
}) => {
  return (
    <Button
      style={{
        color: "white",
        backgroundColor: bgColor || "#4A5568",
        width: width,
      }}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
};
