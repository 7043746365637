import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, InfoIcon } from "@chakra-ui/icons";
import axios from "axios";
import useCustomToast from "../hooks/useCustomToast";
// import { Sapling } from "@saplingai/sapling-js/observer";
import {
  boldContentProcessor,
  italicContentProcessor,
  italicsIdentifier,
  quoteCharSelector,
  formulateFragment,
  createRestOfNode,
  findBoldContent,
  findItalicContent,
  parseSelectedSuggestionDiff,
} from "./helpers/helpers";
import { TinyMcEditorComponent } from "../components/tinymcEditorComponent";
import { CreateVersionComponent } from "./components/createVersionComponent";
import { DocumentVersionListComponent } from "./components/documentVersionListComponent";
import { DownloadVersionComponent } from "./components/downloadVersionComponent";

export const FileEditor = ({
  user,
  s3Key,
  selectedProject,
  setLoading,
  selectedProjectVersion,
  setSelectedProjectVersion,
  isProjectVersionSelected,
  setIsProjectVersionSelected,
}) => {
  const showToast = useCustomToast();
  const editorRef = useRef(null);
  const [initialEditorState, setInitialEditorState] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [editorKey, setEditorKey] = useState(Math.random());
  const [headerContent, setHeaderContent] = useState("");
  const [groupedRecommendations, setGroupedRecommendations] = useState({});
  const [plainLanguageViolations, setPlainLanguageViolations] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [boldContent, setBoldContent] = useState([]);
  const [italicContent, setItalicContent] = useState([]);
  const [editorLoadingState, setEditorLoadingState] = useState(true);
  const [versions, setVersions] = useState([]);
  const [isVersionPolling, setIsVersionPolling] = useState(false);
  const [preSignedUrl, setPresignedUrl] = useState(null);

  // Accept / Ignore state
  const [segmentFault, setSegmentFault] = useState(false);
  const [disabledButtons, setDisableButtons] = useState(false);
  const [plViolationPara, setPlViolationPara] = useState(false);
  const [plViolationIndex, setPlViolationIndex] = useState(null);

  // const API_KEY = process.env.REACT_APP_SAPLINGAI_API_KEY;

  const titleMap = useMemo(() => {
    return {
      has_double_negative: "Double Negatives",
      has_excess_modifiers: "Excessive Modifier Usage",
      has_jargon: "Jargon Usage",
      has_latin_abbreviations: "Latin Abbreviations",
      has_passive_voice: "Passive Voice",
      has_shall: '"Shall" Usage',
      has_slashes: "Usage of Slashes",
      noTopicSentence: "No Topic Sentence",
      multipleTopics: "Multiple Topics per Paragraph",
    };
  }, []);

  const reverseTitleMap = Object.fromEntries(
    Object.entries(titleMap).map(([key, value]) => [value, key])
  );

  const groupPlainLanguageViolations = (violations) => {
    const groups = {
      noTopicSentence: [],
      multipleTopics: [],
      specificHeadings: violations.topic_headings || [],
      maxLevel: violations.level.max_level || 0,
    };

    violations.paragraphs.forEach((item, index) => {
      if (item.error === "no topic sentence") {
        groups.noTopicSentence.push({
          index: index,
          para: item.para,
          acknowledged: false,
        });
      } else if (item.error === "multiple topics") {
        groups.multipleTopics.push({
          index: index,
          para: item.para,
          topics: item.topic,
          identifiedTopics: item.topic.join("; "),
          acknowledged: false,
        });
      } else {
        console.log("Unknown violation type:", item.error);
      }
    });

    return groups;
  };

  useEffect(() => {
    const groupRecommendations = (data) => {
      const groups = {};
      const keys = [
        "has_double_negative",
        "has_excess_modifiers",
        "has_jargon",
        "has_latin_abbreviations",
        "has_passive_voice",
        "has_shall",
        "has_slashes",
      ];
      keys.forEach((key) => {
        groups[key] = [];
      });

      data.forEach((item) => {
        let violations = [];
        keys.forEach((key) => {
          if (item[key] === true) {
            violations.push(titleMap[key] || key.replace(/_/g, " ")); // Add the violation to the list
            groups[key].push({
              sentence: item.sentence,
              rewrite: item.rewrite,
              excess_modifiers: item.has_excess_modifiers
                ? item.excess_modifiers
                : "",
              violations: violations, // Add the violations array to the recommendation
            });
          }
        });
      });

      return groups;
    };

    if (isProjectVersionSelected) {
      if (
        selectedProjectVersion &&
        selectedProjectVersion.recommendation_text
      ) {
        const groups = groupRecommendations(
          selectedProjectVersion.recommendation_text
        );
        setGroupedRecommendations(groups);
      }
      if (selectedProjectVersion && selectedProjectVersion.pl_violation) {
        const groups = groupPlainLanguageViolations(
          selectedProjectVersion.pl_violation
        );
        setPlainLanguageViolations(groups);
      }
    } else {
      if (selectedProject && selectedProject.recommendation_text) {
        const groups = groupRecommendations(
          selectedProject.recommendation_text
        );
        setGroupedRecommendations(groups);
      }
      if (selectedProject && selectedProject.pl_violation) {
        const groups = groupPlainLanguageViolations(
          selectedProject.pl_violation
        );
        setPlainLanguageViolations(groups);
      }
    }
  }, [selectedProject, titleMap, selectedProjectVersion]);

  // useEffect(() => {
  //   Sapling.init({
  //     key: API_KEY,
  //     endpointHostname: "https://api.sapling.ai",
  //     editPathname: "/api/v1/edits",
  //     statusBadge: true,
  //     simplifications: true,
  //     hard_to_read: true,
  //     autoShowAnnotations: true,
  //     lang: "en",
  //     autocomplete: true,
  //     mode: "dev",
  //   });
  // }, [API_KEY]);

  useEffect(() => {
    const fetchData = async () => {
      if (s3Key) {
        let action;

        if (s3Key.endsWith(".docx")) {
          action = "convertDocx";
        } else if (s3Key.endsWith(".pdf")) {
          action = "convertPDF";
        } else if (s3Key.endsWith(".html")) {
          action = "convertHTML";
        } else {
          console.error("Unsupported file type:", s3Key);
          setLoading(false);
          return;
        }

        const response = await axios.post(
          process.env.REACT_APP_GENERATE_PRESIGNED_URL_API,
          {
            key: s3Key,
            action: "getObject",
          }
        );
        const preSignedUrl = JSON.parse(response.data.body).url;

        try {
          setInitialEditorState(null);
          setPresignedUrl(null);

          const response = await axios.get(
            process.env.REACT_APP_DOCX_TO_HTML_API_URL,
            {
              params: {
                action: action,
                s3url: preSignedUrl,
              },
            }
          );

          if (response.status === 200) {
            const responseBody = response.data;
            const htmlContent = responseBody.html;
            setInitialEditorState(htmlContent);
            setPresignedUrl(preSignedUrl);
          } else {
            console.error(
              "Error converting file to HTML:",
              response.statusText
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    };

    if (s3Key) {
      setLoading(true);
      fetchData();
    }
  }, [s3Key, setLoading]);

  useEffect(() => {
    const isDataLoaded =
      groupedRecommendations &&
      Object.keys(groupedRecommendations).length > 0 &&
      plainLanguageViolations &&
      Object.keys(plainLanguageViolations).length > 0;

    if (isDataLoaded) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [groupedRecommendations, plainLanguageViolations, setLoading]);

  useEffect(() => {
    if (selectedProject && selectedProject.bert_text) {
      const badLines = selectedProject.bert_text.map((item) => item);
      setSuggestions(badLines);
    }
  }, [selectedProject.bert_text]);

  useEffect(() => {
    if (editorRef.current && selectedProject) {
      const editorContainer = editorRef.current.editor.editorContainer;
      const header = editorContainer.querySelector(".custom-editor-header");
      if (header) {
        // Clear the existing content
        header.innerHTML = "";
        // Create & set the new document name
        const docName = document.createElement("span");
        docName.textContent = selectedProject.document_name;
        header.appendChild(docName);
      }
    }
  }, [selectedProject, editorRef]);

  useEffect(() => {
    if (!initialEditorState) {
      setEditorLoadingState(true);
    } else {
      setEditorLoadingState(false);
    }
  }, [initialEditorState]);

  const jumpToParagraph = (paragraphText) => {
    setDisableButtons(false);
    setSegmentFault(false);

    if (!editorRef.current) {
      console.error("Editor not initialized");
      return;
    }

    const editor = editorRef.current.editor;
    const contentWindow = editor.getWin();
    const iframeElement = editor.iframeElement;

    findBoldContent(contentWindow, setBoldContent);
    findItalicContent(contentWindow, setItalicContent);

    // Clear previous selections
    if (contentWindow.getSelection) {
      const sel = contentWindow.getSelection();
      sel.removeAllRanges();
    }

    // Try searching for the complete text first
    let found = contentWindow.find(paragraphText.trim());
    let range;

    if (!found) {
      // remove double spaces
      paragraphText = paragraphText.replace(/\s\s+/g, " ");
      found = contentWindow.find(paragraphText.trim());
    }

    if (!found) {
      console.log("Complete text not found in the editor:", paragraphText);
      // If not found, split the search text at every potential interruption point (like spaces or punctuation)
      const searchTextSegments = paragraphText.trim().split(/[\s,.]+/);
      console.log("Searching for segments:", searchTextSegments);
      let foundAllSegments = true;

      // Search each segment in the document
      searchTextSegments.forEach((segment, index) => {
        if (!contentWindow.find(segment)) {
          console.error(`Segment not found in the editor: ${segment}`);
          foundAllSegments = false;
          setSegmentFault(true);
          return;
        } else {
          // If it's the first segment, save its range to use for scrolling and highlighting
          if (index === 0 && contentWindow.getSelection().rangeCount > 0) {
            range = contentWindow.getSelection().getRangeAt(0);
          }
        }
      });

      // Only proceed if all segments were found
      found = foundAllSegments;
    } else if (contentWindow.getSelection().rangeCount > 0) {
      // If the complete text was found, use its range
      range = contentWindow.getSelection().getRangeAt(0);
    }

    if (found && range) {
      let scrollNode = range.commonAncestorContainer;

      // Navigate up the DOM tree to find the nearest element
      while (scrollNode && scrollNode.nodeType !== 1) {
        // 1 is the nodeType for Element
        scrollNode = scrollNode.parentNode;
      }

      if (scrollNode) {
        // Get the bounding client rectangle of the scrollNode
        const rect = scrollNode.getBoundingClientRect();
        // Get the top position of the scrollNode relative to the iframe
        const relativeTop = rect.top + iframeElement.contentWindow.scrollY;

        // Calculate the position to scroll to in the iframe
        const positionToScroll = relativeTop - iframeElement.clientHeight / 2;

        // Scroll the iframe's content window to the position of the element
        iframeElement.contentWindow.scrollTo({
          top: positionToScroll,
          behavior: "smooth",
        });
      } else {
        console.error("Couldn't find a scrollable node");
      }
      setSelectedRange(range);
    } else {
      console.error("Complete text not found in the editor:", paragraphText);
    }
  };

  const GroupedRecommendations = ({ recommendations, disabled }) => {
    const previewLength = 10;
    return (
      <Accordion allowToggle>
        {recommendations &&
          Object.entries(recommendations).map(([group, items], index) => {
            if (Array.isArray(items) && items.length > 0) {
              const userFriendlyTitle =
                titleMap[group] || group.replace(/_/g, " ");
              return (
                <Box
                  key={index}
                  m={3} // Margins around each accordion box
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius="md"
                  boxShadow="sm" // Optional: adds a subtle shadow to each accordion box
                  backgroundColor="white"
                >
                  <AccordionItem key={index} isDisabled={disabled}>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {`${userFriendlyTitle} (${items.length})`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {items.map((item, subIndex) => (
                        <Box
                          key={subIndex}
                          my="2"
                          p="2"
                          borderRadius="md"
                          borderWidth="1px"
                          borderColor="gray.200"
                          backgroundColor="gray.50"
                          cursor="pointer"
                          onClick={() =>
                            handleSuggestionAction(item, group, true)
                          }
                        >
                          <Text fontWeight="bold" mb="1">
                            Sentence:
                          </Text>
                          <Text mb="2">
                            {item.sentence
                              .split(" ")
                              .slice(0, previewLength)
                              .join(" ")}
                            ...
                          </Text>
                          <Text fontWeight="bold" mb="1">
                            Rewrite:
                          </Text>
                          <Text>
                            {item.rewrite
                              .split(" ")
                              .slice(0, previewLength)
                              .join(" ")}
                            ...
                          </Text>
                        </Box>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Box>
              );
            }
            return null;
          })}
      </Accordion>
    );
  };

  const PlainLanguageViolations = ({ violations, disabled }) => {
    const previewLength = 10;
    return (
      <Accordion allowToggle>
        {violations &&
          Object.entries(violations).map(([violationType, items], index) => {
            // Skip rendering for specificHeadings and maxLevel
            if (
              violationType === "specificHeadings" ||
              violationType === "maxLevel"
            ) {
              return null;
            }

            const userFriendlyTitle =
              titleMap[violationType] || violationType.replace(/_/g, " ");

            if (Array.isArray(items) && items.length > 0) {
              return (
                <Box
                  key={`${violationType}-${index}`}
                  m={3} // Margins around each accordion box
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius="md"
                  boxShadow="sm" // Optional: adds a subtle shadow to each accordion box
                  backgroundColor="white"
                >
                  <AccordionItem
                    key={`${violationType}-${index}`}
                    isDisabled={disabled}
                  >
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {`${userFriendlyTitle} (${items.length})`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {items.map((item, subIndex) => (
                        <Box
                          key={`${violationType}-item-${subIndex}`}
                          my="2"
                          p="2"
                          borderRadius="md"
                          borderWidth="1px"
                          borderColor={
                            item.acknowledged ? "green.500" : "gray.200"
                          }
                          backgroundColor={
                            item.acknowledged ? "green.50" : "gray.50"
                          }
                          cursor="pointer"
                          onClick={() =>
                            handleSuggestionAction(
                              item,
                              violationType,
                              true,
                              subIndex
                            )
                          }
                        >
                          <Text mb="2">
                            {item.para
                              ? item.para
                                  .split(" ")
                                  .slice(0, previewLength)
                                  .join(" ")
                              : item
                                  .toString()
                                  .split(" ")
                                  .slice(0, previewLength)
                                  .join(" ")}
                            ...
                          </Text>
                          {item.identifiedTopics && (
                            <>
                              <Text fontWeight="bold" mt="2">
                                Identified Topics:
                              </Text>
                              <Text>{item.identifiedTopics}</Text>
                            </>
                          )}
                        </Box>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Box>
              );
            }
            return null;
          })}
      </Accordion>
    );
  };

  const removeSuggestion = (suggestion) => {
    setGroupedRecommendations((prevRecommendations) => {
      let newRecommendations = JSON.parse(JSON.stringify(prevRecommendations));

      suggestion.violations.forEach((violationName) => {
        // Convert user-friendly names to keys
        const violationKey = reverseTitleMap[violationName] || violationName;

        if (newRecommendations[violationKey]) {
          newRecommendations[violationKey] = newRecommendations[
            violationKey
          ].filter((item) => item.sentence !== suggestion.sentence);
        } else {
          console.log(
            `Violation type '${violationName}' (key: '${violationKey}') not found in grouped recommendations.`
          );
        }
      });

      return newRecommendations;
    });
  };

  const handleAcceptIgnoreClick = (action, suggestion, event) => {
    event.preventDefault(); // Prevent the default form submit behavior

    if (!suggestion || !suggestion.violations) {
      console.error("Invalid suggestion:", suggestion);
      return;
    }

    if (segmentFault) {
      const segmentFaultInfoMessage =
        "There was a problem parsing and replacing paragraph segments.  You can still use the Compliant Rewrite section provided to copy/paste.";
      showToast(segmentFaultInfoMessage, "info", 5000);
      setDisableButtons(true);
    }

    if (action === "accept" && editorRef.current && selectedRange) {
      const editor = editorRef.current.editor;
      editor.focus(); // Focus on the editor

      // Use the previously saved range to replace the text
      const contentWindow = editor.getWin();
      const sel = contentWindow.getSelection();
      if (sel.rangeCount) {
        sel.removeAllRanges(); // Clear any existing selections
        sel.addRange(selectedRange); // Add the saved range

        // Get the selected range
        const range = sel.getRangeAt(0);
        // Delete the content of the range (the original sentence)
        range.deleteContents();

        let replacementText = suggestion.rewrite;
        // Create a document fragment to hold the new nodes
        const fragment = document.createDocumentFragment();

        ////// Initialize Variables //////
        let boldedSentenceObj = {
          boldedSentenceTitle: "",
          boldedSentence: "",
          boldedWholeSentence: false,
        };
        let italicizedSentenceObj = {
          italicizedWholeSentence: false,
          italicizedSentence: "",
        };
        let quotedSentence = false;
        let quotedChars = { prepend: '"', append: '"' };
        const trimmedSentence = suggestion.sentence.trim();

        // identify if italics are present
        quotedSentence = italicsIdentifier(trimmedSentence);

        // get specific quote characters
        quoteCharSelector(trimmedSentence, quotedChars);

        // processes bolded content from suggestion
        boldContentProcessor(
          trimmedSentence,
          boldedSentenceObj,
          quotedSentence,
          quotedChars,
          boldContent
        );

        // processes italics content from suggestion
        italicContentProcessor(
          trimmedSentence,
          italicizedSentenceObj,
          quotedSentence,
          quotedChars,
          italicContent
        );

        // calc bold or italicized content for replacement text
        if (boldedSentenceObj.boldedWholeSentence) {
          formulateFragment(fragment, replacementText, "strong");
        } else if (boldedSentenceObj.boldedSentenceTitle) {
          const replacementTextTitle =
            boldedSentenceObj.boldedSentenceTitle + " ";
          formulateFragment(fragment, replacementTextTitle, "strong");
          createRestOfNode(fragment, replacementText);
        } else if (italicizedSentenceObj.italicizedWholeSentence) {
          formulateFragment(fragment, replacementText, "i");
        } else {
          createRestOfNode(fragment, replacementText);
        }

        if (quotedSentence) {
          fragment.prepend(quotedChars.prepend);
          fragment.append(quotedChars.append);
        }

        range.insertNode(fragment);

        updateEditorState();
        removeSuggestion(suggestion);
        setIsModalOpen(false);
        setSelectedRange(null); // Clear the saved range for future use
      } else {
        console.error("No range selected in the editor:", suggestion.sentence);
      }
    } else if (action === "ignore") {
      removeSuggestion(suggestion);
      setIsModalOpen(false);
    }
  };

  const handleSuggestionAction = (
    suggestion,
    violationType,
    shouldJump,
    index
  ) => {
    const { highlightedSentence, highlightedRewrite } =
      parseSelectedSuggestionDiff(suggestion, violationType);

    setSelectedSuggestion({
      ...suggestion,
      violationType: violationType,
      highlightedSentence,
      highlightedRewrite,
    });

    setIsModalOpen(true);

    if (suggestion.sentence) {
      if (shouldJump) {
        setPlViolationPara(false);
        jumpToParagraph(suggestion.sentence);
      }
    }

    if (suggestion.para) {
      if (shouldJump) {
        setPlViolationPara(true);
        setPlViolationIndex(index);
        jumpToParagraph(suggestion.para);
      }
    }
  };

  const updateEditorState = () => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      const newContent = editor.getContent();
      setInitialEditorState(newContent);
    } else {
      console.error("Editor reference is not available.");
    }
  };

  return (
    <Box width={"100%"}>
      <Flex
        flexDirection="row"
        flex="1"
        maxHeight="calc(100vh - 200px)"
        backgroundColor="#edf2f7"
        borderRadius="md"
        p={2}
      >
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            paddingLeft={4}
            paddingRight={4}
          >
            <Box display={"flex"}>
              <CreateVersionComponent
                htmlContent={initialEditorState}
                project={selectedProject}
                setLoading={setLoading}
                versionsList={versions}
                user={user}
                setIsVersionPolling={setIsVersionPolling}
                editorLoadingState={editorLoadingState}
              />
              <DocumentVersionListComponent
                project={selectedProject}
                user={user}
                versions={versions}
                setVersions={setVersions}
                isVersionPolling={isVersionPolling}
                setIsVersionPolling={setIsVersionPolling}
                selectedProjectVersion={selectedProjectVersion}
                setSelectedProjectVersion={setSelectedProjectVersion}
                setIsProjectVersionSelected={setIsProjectVersionSelected}
              />
            </Box>

            <Box>
              {isProjectVersionSelected && (
                <DownloadVersionComponent
                  s3Key={s3Key}
                  selectedProjectVersion={selectedProjectVersion}
                />
              )}
            </Box>
          </Box>
          <Box
            flex="1"
            mx={4}
            mt={2}
            mb={2}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            shadow="md"
            backgroundColor="white"
            alignContent={editorLoadingState && "center"}
          >
            {!editorLoadingState ? (
              <TinyMcEditorComponent
                editorRef={editorRef}
                initialEditorState={initialEditorState}
                setInitialEditorState={setInitialEditorState}
              />
            ) : (
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                width={"1000px"} // refactor for screen sizes
              >
                <Spinner size="lg" color="red" justifyContent={"center"} />
                <Text pl={"2"} fontSize={"sm"}>
                  Loading Files Contents...
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
        <Flex flexDirection="column" w="325px" mx={4} mt={2} mb={2}>
          <Heading as="h3" size="lg" mb={2} color="#2D3748">
            Plain Language Rules
          </Heading>
          <Box overflowY="auto">
            <Heading as="h3" size="sm" mb={2} color="#2D3748" pl="3" pt="4">
              Recommendations
            </Heading>
            <GroupedRecommendations
              recommendations={groupedRecommendations}
              disabled={editorLoadingState}
            />
            {(plainLanguageViolations.multipleTopics?.length > 0 ||
              plainLanguageViolations.noTopicSentence?.length > 0) && (
              <Heading as="h3" size="sm" mb={2} color="#2D3748" pl="3" pt="4">
                Violations
              </Heading>
            )}
            <PlainLanguageViolations
              violations={plainLanguageViolations}
              disabled={editorLoadingState}
            />
          </Box>
        </Flex>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Compliance Details</ModalHeader>
              <ModalCloseButton
                onClick={(e) => {
                  setDisableButtons(false);
                }}
              />
              <ModalBody>
                {/* Display the tag for the plain language violations only for recommendations */}
                {selectedSuggestion?.violationType &&
                  selectedSuggestion.violations &&
                  !selectedSuggestion.identifiedTopics && (
                    <>
                      <Text fontWeight="bold">
                        Plain Language Violation(s):
                      </Text>
                      <Flex mt="2" mb="4" ml="2" wrap="wrap">
                        {selectedSuggestion.violations.map(
                          (violation, index) => (
                            <Box
                              key={index}
                              mr="2"
                              px="0.5"
                              py="0.25"
                              fontSize="sm"
                              borderRadius="md"
                              backgroundColor="orange.200"
                            >
                              {violation}
                            </Box>
                          )
                        )}
                      </Flex>
                    </>
                  )}
                {/* Render for "no topic sentence" violation */}
                {selectedSuggestion?.violationType === "noTopicSentence" && (
                  <>
                    {/* Display the tag for the violation type */}
                    <Text fontWeight="bold">Plain Language Violation(s):</Text>
                    <Flex mb="4" mt="2" ml="2" wrap="wrap">
                      <Box
                        px="0.5"
                        py="0.25"
                        fontSize="sm"
                        borderRadius="md"
                        backgroundColor="orange.200"
                      >
                        {titleMap[selectedSuggestion.violationType] ||
                          selectedSuggestion.violationType}
                      </Box>
                    </Flex>
                    <Text fontWeight="bold">No Topic Sentence:</Text>
                    <Text mb="4">{selectedSuggestion.para}</Text>
                  </>
                )}
                {/* Render for "multiple topics" violation */}
                {selectedSuggestion?.identifiedTopics && (
                  <>
                    {/* Display the tag for the violation type */}
                    <Text fontWeight="bold">Plain Language Violation(s):</Text>
                    <Flex mb="4" mt="2" ml="2" wrap="wrap">
                      <Box
                        px="0.5"
                        py="0.25"
                        fontSize="sm"
                        borderRadius="md"
                        backgroundColor="orange.200"
                      >
                        {titleMap[selectedSuggestion.violationType] ||
                          selectedSuggestion.violationType}
                      </Box>
                    </Flex>
                    <Text fontWeight="bold">
                      Paragraph with Multiple Topics:
                    </Text>
                    <Text mb="4">{selectedSuggestion.para}</Text>
                    <Text fontWeight="bold">Identified Topics:</Text>
                    <Text mb="4">{selectedSuggestion.identifiedTopics}</Text>
                  </>
                )}
                {/* Display excess modifiers if applicable */}
                {selectedSuggestion?.excess_modifiers && (
                  <>
                    <Text fontWeight="bold">Excess Modifiers:</Text>
                    <Text mb="4">{selectedSuggestion.excess_modifiers}</Text>
                  </>
                )}
                {/* Render for general recommendations */}
                {selectedSuggestion?.violations &&
                  !selectedSuggestion.identifiedTopics && (
                    <>
                      <Text fontWeight="bold">Bad Sentence:</Text>
                      <Text mb="4">
                        {selectedSuggestion.highlightedSentence}
                      </Text>
                      <Text fontWeight="bold">Compliant Rewrite:</Text>
                      <Text mb="4">
                        {selectedSuggestion.highlightedRewrite}
                      </Text>
                    </>
                  )}
              </ModalBody>
              <ModalFooter>
                {disabledButtons ? (
                  <Button
                    type="button"
                    colorScheme="orange"
                    leftIcon={<InfoIcon />}
                    mr={3}
                    onClick={(e) => {
                      console.log("Reported!!!");
                      setSegmentFault(false);
                      setIsModalOpen(false);
                      setDisableButtons(false);
                    }}
                  >
                    Report
                  </Button>
                ) : plViolationPara ? (
                  <>
                    <Button
                      type="button"
                      colorScheme="green"
                      leftIcon={<CheckIcon />}
                      mr={3}
                      onClick={(e) => {
                        setSegmentFault(false);
                        setIsModalOpen(false);
                        if (
                          selectedSuggestion.violationType === "multipleTopics"
                        ) {
                          plainLanguageViolations.multipleTopics[
                            plViolationIndex
                          ].acknowledged = true;
                        }
                        if (
                          selectedSuggestion.violationType === "noTopicSentence"
                        ) {
                          plainLanguageViolations.noTopicSentence[
                            plViolationIndex
                          ].acknowledged = true;
                        }
                        setPlViolationIndex(null);
                      }}
                    >
                      Acknowledged
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="button"
                      colorScheme="green"
                      leftIcon={<CheckIcon />}
                      mr={3}
                      onClick={(e) =>
                        handleAcceptIgnoreClick("accept", selectedSuggestion, e)
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      type="button"
                      colorScheme="red"
                      leftIcon={<CloseIcon />}
                      onClick={(e) =>
                        handleAcceptIgnoreClick("ignore", selectedSuggestion, e)
                      }
                    >
                      Ignore
                    </Button>
                  </>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Flex>
    </Box>
  );
};
