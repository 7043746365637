import { Box } from "@chakra-ui/react";

export const StyledLandingPageContainer = ({ children }) => {
  return (
    <Box height={"100%"} overflowY={"auto"}>
      {children}
    </Box>
  );
};

export const StyledHeroContainer = ({ children, refProp }) => {
  return <Box ref={refProp}>{children}</Box>;
};

export const StyledTilesContainer = ({ children }) => {
  return <Box>{children}</Box>;
};

export const StyledFeatureColumnContainer = ({ children, refProp }) => {
  return <Box ref={refProp}>{children}</Box>;
};

export const StyledFeatureHeadlineContainer = ({ children, refProp }) => {
  return <Box ref={refProp}>{children}</Box>;
};

export const StyledWhyUsContainer = ({ children, refProp }) => {
  return <Box ref={refProp}>{children}</Box>;
};

export const StyledContactUsContainer = ({ children, refProp }) => {
  return <Box ref={refProp}>{children}</Box>;
};
