import React, { useState } from "react";
import LoginPage from "./pages/login/LoginPage";
import { forgotPassword, confirmForgotPassword, signUp } from "./api/user";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./contexts/auth";

import "./App.css";
import Header from "./components/headerComponent/index";
import Home from "./pages/home/Home";
import UserRegistration from "./pages/register/UserRegistration";
import ObfuscationPage from "./pages/login/ObfuscationPage";
import { LandingPage } from "./pages/landing";
import Profile from "./pages/Profile";
import { FileEditor } from "./editor";
import StyleGuides from "./pages/styleGuides/StyleGuides";
import { Navigate } from "react-router-dom";
import * as routes from "./constants/routes";

// TODO: kevin.newcomb@fedwriters.com
// 1. Finish clean up / refactor components / pages and deploy changes
// 2. Get iteration 1 out for new feature for multi para port from proposal tool
// 3. Get working on ROLES for users.  ie: BASIC, PRO, ADMIN

const App = () => {
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated;

  return (
    <>
      <div className="app-container">
        {isAuthenticated && <Header user={auth.user} />}
        <Routes>
          {/* Obfuscation Page */}
          <Route
            path={routes.OBFUSCATION}
            element={
              <ObfuscationPage
                onObfuscationPassed={() => auth.obfuscated(true)}
              />
            }
          />

          {/* Login Page */}
          <Route
            path={routes.LOGIN}
            element={
              !auth.isObfuscationPassed ? (
                <Navigate to={routes.OBFUSCATION} />
              ) : isAuthenticated ? (
                <Navigate to={routes.HOME} />
              ) : (
                <LoginPage />
              )
            }
          />

          {/* Register Page */}
          <Route
            path={routes.REGISTER}
            element={
              !auth.isObfuscationPassed ? (
                <Navigate to={routes.OBFUSCATION} />
              ) : isAuthenticated ? (
                <Navigate to={routes.HOME} />
              ) : (
                <UserRegistration />
              )
            }
          />

          {/* Home Page */}
          <Route
            path={routes.HOME}
            element={isAuthenticated && <Home user={auth.user} />}
          />

          {/* Profile Page */}
          <Route
            path={routes.PROFILE}
            element={isAuthenticated && <Profile />}
          />

          {/* Edit Page */}
          <Route
            path={routes.EDIT}
            element={isAuthenticated && <FileEditor />}
          />

          {/* Style Guides Page */}
          <Route
            path={routes.STYLE_GUIDES}
            element={isAuthenticated && <StyleGuides />}
          />

          {/* Landing Page */}
          <Route
            path={routes.LANDING}
            element={
              isAuthenticated ? <Navigate to={routes.HOME} /> : <LandingPage />
            }
          />

          {/* Fallback route */}
          <Route
            path="*"
            element={
              <Navigate
                to={isAuthenticated ? routes.HOME : routes.LANDING}
                replace
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
export default App;
