import React, { useEffect, useState, useRef, useMemo } from "react";
// import { Sapling } from "@saplingai/sapling-js/observer";
import { Editor } from "@tinymce/tinymce-react";
import "./tinymce.css";

export const TinyMcEditorComponent = ({
  initialEditorState,
  setInitialEditorState,
  editorRef,
}) => {
  const [headerContent, setHeaderContent] = useState("");
  const [editorKey, setEditorKey] = useState(Math.random());

  const handleEditorInit = () => {
    const promotionElement = document.querySelector(".tox-promotion");
    if (promotionElement) {
      promotionElement.parentNode.removeChild(promotionElement);
    }
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      const editorBody = editor.getBody();
      const editorContainer = editor.editorContainer;

      let header = editorContainer.querySelector(".custom-editor-header");

      // If the custom header doesn't exist, create it.
      if (!header) {
        header = document.createElement("div");
        header.className = "custom-editor-header";
        header.style.backgroundColor = "#ffffff";
        header.style.padding = "10px";
        header.style.borderBottom = "1px solid #e0e0e0";
        header.style.display = "flex";
        header.style.justifyContent = "center";
        editorContainer.prepend(header);
      }

      // Update the header content.
      header.innerHTML = ""; // Clear the existing content
      const docName = document.createElement("span");
      docName.textContent = headerContent;
      header.appendChild(docName);

      // Sapling.observe(editorBody);
    }
  };

  return (
    <Editor
      ref={editorRef}
      key={editorKey}
      onInit={handleEditorInit}
      tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
      initialValue={initialEditorState}
      init={{
        base_url: process.env.PUBLIC_URL + "/tinymce",
        suffix: ".min",
        height: "calc(100vh - 270px)",
        menubar: true,
        branding: false,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "preview",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "help",
          "wordcount",
          "emoticons",
          "nonbreaking",
        ],
        toolbar:
          "undo redo | formatselect | bold italic underline strikethrough | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | link unlink anchor image | \
                            code blockquote formatselect fontselect fontsizeselect | \
                            cut copy paste removeformat | forecolor backcolor | \
                            searchreplace | fullscreen | charmap nonbreaking",
      }}
      onBlur={(e, editor) => {
        const newContent = editor.getContent();
        setInitialEditorState(newContent);
      }}
    />
  );
};
