import { Box, Flex, Text, Image } from "@chakra-ui/react";
import React from "react";

export const StyledFooterComponent = ({ bgColor, logo }) => {
  return (
    <Box
      py="8"
      pr="16"
      pl="16"
      w="full"
      bg={bgColor}
      bottom="0"
      left="0"
      right="0"
    >
      <Flex direction="column" justify="flex-start">
        <Image
          width={180}
          height={50}
          fit="contain"
          src={logo}
          alt="PlainLanguagePro Logo"
        />
        <Text fontSize="sm">© 2024 BoomTech LLC. All rights reserved.</Text>
      </Flex>
    </Box>
  );
};
