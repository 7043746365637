import { Text, useTheme, useBreakpointValue, Collapse } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

export const StyledHeaderComponent = ({
  bgColor,
  logo,
  onFeatureClick,
  onComplianceClick,
  onWhyUsClick,
  onContactUsClick,
  onLogoClick,
}) => {
  const [showMenu, setShowMenu] = useState(true);
  const [ref, setRef] = useState("");
  const theme = useTheme();
  const isMedScreenSize = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    // Toggle showMenu based on screen size change
    if (isMedScreenSize) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [isMedScreenSize]);

  useEffect(() => {
    if (ref === "features") {
      onFeatureClick();
      setRef("");
    } else if (ref === "compliance") {
      onComplianceClick();
      setRef("");
    } else if (ref === "whyus") {
      onWhyUsClick();
      setRef("");
    } else if (ref === "contactus") {
      onContactUsClick();
      setRef("");
    } else {
      setRef("");
    }
  }, [onComplianceClick, onContactUsClick, onFeatureClick, onWhyUsClick, ref]);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleOnClick = (refString) => {
    setShowMenu(!showMenu);
    setRef(refString);
  };

  return (
    <S.StyledHeaderContainer bgColor={bgColor}>
      <S.StyledLogo logo={logo} onClick={onLogoClick} />
      <S.StyledOuterHStack>
        <S.StyledHamburgerMenuButton handleToggleMenu={handleToggleMenu} />
        <Collapse in={showMenu || isMedScreenSize} animateOpacity>
          <S.StyledMenuContainer bgColor={bgColor}>
            <S.StyledInnerMenuHStack showMenu={showMenu}>
              <Text
                cursor="pointer"
                onClick={() => {
                  handleOnClick("features");
                }}
              >
                Features
              </Text>
              <Text
                cursor="pointer"
                onClick={() => {
                  handleOnClick("compliance");
                }}
              >
                Compliance
              </Text>
              <Text
                cursor="pointer"
                onClick={() => {
                  handleOnClick("whyus");
                }}
              >
                Why Us
              </Text>
              <Link to="/login">
                <Text>Sign In</Text>
              </Link>
              <S.StyledContactUsButton
                bgColor={theme.colors.accentOne}
                onClick={() => {
                  handleOnClick("contactus");
                }}
              >
                Contact Us
              </S.StyledContactUsButton>
            </S.StyledInnerMenuHStack>
          </S.StyledMenuContainer>
        </Collapse>
      </S.StyledOuterHStack>
    </S.StyledHeaderContainer>
  );
};
