import { Flex, Box, Heading, Image } from "@chakra-ui/react";

export const StyledFlexTileContainer = ({ children }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      pt={16}
      pb={{ base: 0, md: 6 }}
      overflow={"hidden"}
    >
      {children}
    </Flex>
  );
};

export const StyledTilesHeading = ({ children }) => {
  return (
    <Heading pb={4} fontSize={{ base: "lg", md: "2xl" }}>
      {children}
    </Heading>
  );
};

export const StyledWhiteSpaceBox = ({ left, right }) => {
  return (
    <Box
      position="absolute"
      left={left}
      right={right}
      top={0}
      height="100%"
      bg="white"
      width="8.5%" // Adjust width as needed
      zIndex={98}
    />
  );
};

export const StyledIndividualLogoContainer = ({
  children,
  index,
  logosLength,
  currIndex,
}) => {
  return (
    <Box
      key={index}
      position="absolute"
      display="flex"
      width={{ base: `${260 / logosLength}%`, md: `${125 / logosLength}%` }}
      justifyContent={"center"}
      left={{
        base: `${
          ((index - currIndex + logosLength) % logosLength) *
          (350 / logosLength)
        }%`,
        md: `${
          ((index - currIndex + logosLength) % logosLength) *
          (175 / logosLength)
        }%`,
      }}
      transition="left 3s linear"
      overflow={"hidden"}
    >
      {children}
    </Box>
  );
};

export const StyledLogoContainer = ({ children }) => {
  return (
    <Box
      display="flex"
      position="relative"
      width="110%"
      height={{ base: "100px", md: "200px" }}
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

export const StyledImage = ({ index, logo }) => {
  const fda = index === 7;
  const nasa = index === 10;

  return (
    <Image
      key={index}
      src={logo}
      alt={`Logo ${index + 1}`}
      w={{ base: "75px", md: "125px" }}
      h={{
        base: fda ? "50px" : nasa ? "60px" : "75px",
        md: fda ? "50px" : nasa ? "100px" : "125px",
      }}
      m="4px"
    />
  );
};
