import { React, useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  GetProjectVersions,
  DeleteProjectVersion,
} from "../../../api/plainLanguageHandler";

export const DocumentVersionListComponent = ({
  project,
  user,
  versions,
  setVersions,
  isVersionPolling,
  setIsVersionPolling,
  selectedProjectVersion,
  setSelectedProjectVersion,
  setIsProjectVersionSelected,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const COMPLETION_TIME_LIMIT = 20 * 60 * 1000;

  const fetchVersions = async () => {
    setIsLoading(true);
    const response = await GetProjectVersions(user.email, project.id); // Assuming GetProjectVersions is async
    if (Array.isArray(response)) {
      setVersions(response);
    } else {
      console.error("Expected an array, received:", typeof response);
      setVersions([]); // Ensure versions is always an array
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVersions();
  }, [user.email, project.id, isVersionPolling]); // Added email to dependency array if it's used in GetProjectVersions

  useEffect(() => {
    let interval;
    if (isVersionPolling) {
      interval = setInterval(() => {
        fetchVersions();
      }, 10000); // Poll every 10 seconds, adjust as needed
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isVersionPolling]);

  const versionDataLoaded = (version) => {
    if (
      version.recommendation_text &&
      version.pl_violation &&
      version.flesch &&
      version.dale_chall &&
      version.mlwf_with_stop &&
      version.mlwf_without_stop
    ) {
      return true;
    } else {
      return false;
    }
  };

  const requiresPolling = (version) => {
    const versionCreatedTime = new Date(version.created_at).getTime();
    const currentTime = new Date().getTime();
    const timeSinceCreation = currentTime - versionCreatedTime;

    // Project requires polling if it is not complete, not in error, and within the time limit
    return (
      !versionDataLoaded(version) && timeSinceCreation <= COMPLETION_TIME_LIMIT
    );
  };

  useEffect(() => {
    let shouldPoll = versions.some(requiresPolling);
    setIsVersionPolling(shouldPoll);
  }, [versions]);

  const getStatusDisplay = (version) => {
    const completionTimeLimit = 20 * 60 * 1000; // 20 minutes in milliseconds
    const versionCreatedTime = new Date(version.created_at).getTime();
    const currentTime = new Date().getTime();
    const timeSinceCreation = currentTime - versionCreatedTime;
    if (versionDataLoaded(version)) {
      return <Text>Completed</Text>;
    } else if (timeSinceCreation <= completionTimeLimit) {
      return (
        <Text style={{ fontStyle: "italic", color: "darkgrey" }}>
          Processing File
        </Text>
      );
    } else {
      return <Text style={{ color: "lightcoral" }}>Error Processing</Text>;
    }
  };

  const handleDeleteVersion = async (version) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      const response = await DeleteProjectVersion(user.email, version.id); // Assuming DeleteProjectVersion is async

      if (response.status === 200) {
        console.log("Version deleted successfully");

        if (
          selectedProjectVersion &&
          selectedProjectVersion.id === version.id
        ) {
          window.location.reload();
        } else {
          fetchVersions();
        }
      }
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        style={{ color: "white", backgroundColor: "#4A5568" }}
      >
        Versions
      </MenuButton>
      <MenuList padding={0} border={0}>
        {!isLoading &&
          versions.length !== 0 &&
          versions.map((version) => (
            <MenuItem
              key={version.id}
              onClick={() => {
                if (!versionDataLoaded(version)) {
                  alert("Version is not ready or errored in processing");
                } else {
                  if (
                    selectedProjectVersion &&
                    selectedProjectVersion.id === version.id
                  ) {
                    return;
                  }
                  setSelectedProjectVersion(version);
                  setIsProjectVersionSelected(true);
                }
              }}
              style={{
                background: "lightGrey",
                color: "black",
                border: "1px solid grey",
                width: "500px",
              }}
              _hover={{
                cursor: versionDataLoaded(version) ? "pointer" : "not-allowed",
                bg: versionDataLoaded(version) ? "gray.200" : "transparent",
              }}
            >
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-evenly"}
              >
                <Box> {version.file_name}</Box>
                <Box> {new Date(version.created_at).toLocaleDateString()}</Box>
                <Box> {getStatusDisplay(version)}</Box>
                <Box cursor={"pointer"}>
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteVersion(version);
                    }}
                  />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};
