import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Text,
  VStack,
  Progress,
  Flex,
  Heading,
  UnorderedList,
  ListItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ButtonComponent } from "../buttonComponent";

export const UploadOrInputComponent = ({
  isOpen,
  onClose,
  setIngressType,
  onNewProjectModalOpen,
  onNewInputProjectOpen,
}) => {
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const modalContentRef = React.useRef(); // Ref for the modal content

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent ref={modalContentRef}>
        <Flex alignSelf={"center"}>
          <Text padding={4}>Please choose an option below:</Text>
        </Flex>
        <Flex
          justifyContent={"space-between"}
          alignContent={"center"}
          padding={6}
          width={"80%"}
          alignSelf={"center"}
        >
          <ButtonComponent
            padding={4}
            onClick={() => {
              onClose();
              onNewProjectModalOpen();
            }}
          >
            Upload Document
          </ButtonComponent>
          <ButtonComponent padding={4} onClick={onNewInputProjectOpen}>
            Input Content
          </ButtonComponent>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
