import acfMainLogo from "./assets/acf_main_logo.png";
import anaLogo from "./assets/ana_logo.png";
import usaidLogo from "./assets/usaid.png";
import usdaLogo from "./assets/usda.png";
import nationalAgriculturalLibraryLogo from "./assets/NationalAgriculturalLibrary-Logo.png";
import airForceLogo from "./assets/US_Air_Force_Logo.png";
import aphisLogo from "./assets/APHIS.png";
import departmentOfJusticeLogo from "./assets/Department_of_Justice.png";
import nationalArchivesLogo from "./assets/National_Archives_and_Records_Administration.png";
import armyLogo from "./assets/us-army-logo.png";
import bureauOfIndianAffairsLogo from "./assets/Seal_of_the_United_States_Bureau_of_Indian_Affairs.png";
import censusBureauLogo from "./assets/Seal_of_the_United_States_Census_Bureau.png";
import departmentOfEducationLogo from "./assets/department-of-education-logo.png";
import cdcLogo from "./assets/United_States_Centers_for_Disease_Control_and_Prevention.png";
import cmsLogo from "./assets/Centers_for_Medicare_and_Medicaid_Services_logo.png";
import edCivilRightsLogo from "./assets/department-of-education-logo.png";
import hhsCivilRightsLogo from "./assets/hhs-office-civil-rights.png";
import cpsLogo from "./assets/department-of-education-logo.png";
import csceLogo from "./assets/csce-logo.png";
import cboLogo from "./assets/Logo_of_the_United_States_Congressional_Budget_Office.png";
import dauLogo from "./assets/dau-logo.png";
import dcmaLogo from "./assets/dcma.png";
import dhaLogo from "./assets/DefenseHealthAgencyDHALogo.png";
import diaLogo from "./assets/DIA.png";
import dlaLogo from "./assets/Defense_Logistics_Agency.png";
import hhsLogo from "./assets/hhs.png";
import dhsLogo from "./assets/Department_of_Homeland_Security.png";
import vaLogo from "./assets/va.png";
import eeseLogo from "./assets/Department_of_Energy.png";
import eiaLogo from "./assets/Eia-logomark.png";
import epaLogo from "./assets/epa-logo.png";
import immigrationReviewLogo from "./assets/Office_for_Immigration_Review.png";
import fsaLogo from "./assets/fsa-logo.png";
import fccLogo from "./assets/fcc-logo.png";
import fhwaLogo from "./assets/fhwa.png";
import flcLogo from "./assets/FLC.png";
import fpsLogo from "./assets/Federal_Protective_Service.png";
import fraLogo from "./assets/FRA_logo.png";
import ftcLogo from "./assets/ftc.png";
import hrsaLogo from "./assets/HRSA-Logo.png";
import ihsLogo from "./assets/Indian_Health_Service_Logo.png";
import iqmLogo from "./assets/epa-logo.png";
import imlsLogo from "./assets/IMLS-Logo.png";
import jcsLogo from "./assets/jcos.png";
import dojJusticeProgramsLogo from "./assets/OfficeOfJusticePrograms-Seal.png";
import bjaLogo from "./assets/Seal-BJA-Logo.png";
import ovicLogo from "./assets/office-for-victims-of-crime.png";
import mccLogo from "./assets/millennium.png";
import nasaLogo from "./assets/nasa-logo.png";
import nciLogo from "./assets/National_Cancer_Institute_logo.png";
import nduLogo from "./assets/National_Defense_University.png";
import nationalGuardLogo from "./assets/ARNG.png";
import odphpLogo from "./assets/ODPHP.png";
import nicLogo from "./assets/nic.png";
import nijLogo from "./assets/nij.png";
import nihLogo from "./assets/NIH.png";
import noaaLogo from "./assets/NOAA.png";
import npsLogo from "./assets/NPS.png";
import nsfLogo from "./assets/NSF.png";
import nrcLogo from "./assets/US-NuclearRegulatoryCommission-Logo.png";
import usmaLogo from "./assets/usma.png";
import usgpoStyleManualLogo from "./assets/Logo_of_the_United_States_Government_Publishing_Office.png";
import placeHolderLogo from "./assets/placeholder-image.png";

export const getAgencyIcons = () => {
  const map = {
    "The Administration for Children and Families": {
      logo: acfMainLogo,
      link: "https://www.acf.hhs.gov/digital-toolbox/content/editorial-style-guide",
    },
    "Administration for Native Americans": {
      logo: anaLogo,
      link: "https://www.acf.hhs.gov/digital-toolbox/content/editorial-style-guide",
    },
    "U.S. Agency for International Development": {
      logo: usaidLogo,
      link: "https://docs.google.com/document/d/1voGPRiFyvgdGsufPXxFA1muup7xZni-FCSRdsjd-xiw/edit",
    },
    "U.S. Department of Agriculture": {
      logo: usdaLogo,
      link: "https://www.usda.gov/style-guide/writing-style",
    },
    "National Agricultural Library": {
      logo: nationalAgriculturalLibraryLogo,
      link: "https://www.usda.gov/style-guide/writing-style",
    },
    "Air Force": {
      logo: airForceLogo,
      link: "https://www.dimoc.mil/VI-Training/DoD-VI-Style-Guide/",
    },
    "Animal and Plant Health Inspection Service": {
      logo: aphisLogo,
      link: "https://www.usda.gov/style-guide/writing-style",
    },
    "Department of Justice": {
      logo: departmentOfJusticeLogo,
      link: "https://www.ojp.gov/pdffiles1/nij/250404.pdf",
    },
    "National Archives": {
      logo: nationalArchivesLogo,
      link: "https://www.archives.gov/files/open/plain-writing/style-guide.pdf?_ga=2.248003335.1258707653.1685980246-400921289.1685980246",
    },
    Army: {
      logo: armyLogo,
      link: "https://www.army.mil/e2/downloads/rv7/armydotmil_style_guide.pdf",
    },
    "Bureau of Indian Affairs": {
      logo: bureauOfIndianAffairsLogo,
      link: "https://www.bia.gov/webstandards/content-guide",
    },
    "Census Bureau": {
      logo: censusBureauLogo,
      link: "https://www.census.gov/about/policies/style-guide.html",
    },
    "Department of Education": {
      logo: departmentOfEducationLogo,
      link: "https://www2.ed.gov/web-guidance/styleguide/index.html",
    },
    "Centers for Disease Control and Prevention": {
      logo: cdcLogo,
      link: "https://developtraining.files.wordpress.com/2013/11/cdc-style-guide.pdf",
    },
    "Centers for Medicare and Medicaid Services": {
      logo: cmsLogo,
      link: "https://www.cms.gov/files/document/requirementsguidepdf",
    },
    "DOJ Civil Rights Division": {
      logo: departmentOfJusticeLogo,
      link: "https://www.ojp.gov/pdffiles1/nij/250404.pdf",
    },
    "ED Office of Civil Rights": {
      logo: edCivilRightsLogo,
      link: "https://www2.ed.gov/web-guidance/styleguide/index.html",
    },
    "HHS Office for Civil Rights": {
      logo: hhsCivilRightsLogo,
      link: "https://www.hhs.gov/web/policies-and-standards/web-style-guide/index.html",
    },
    "Commission on Presidential Scholars": {
      logo: cpsLogo,
      link: "https://www2.ed.gov/web-guidance/styleguide/index.html",
    },
    "Commission on Security and Coopoeration in Europe": {
      logo: csceLogo,
      link: "https://www.csce.gov/style-guide",
    },
    "Congressional Budget Office": {
      logo: cboLogo,
      link: "https://www.cbo.gov/system/files/2018-09/44975-StyleGuide.pdf",
    },
    "Defense Acquisition University": {
      logo: dauLogo,
      link: "https://www.dau.edu/cop/cor/DAU%20Sponsored%20Documents/WSM%20009%20style%20guide%20handout.aspx",
    },
    "Defense Contract Management Agency": {
      logo: dcmaLogo,
      link: "https://www.dcma.mil/Portals/31/Documents/Policy/DCMA-INST 502.pdf?%20target=",
    },
    "Defence Health Agency": {
      logo: dhaLogo,
      link: "https://www.health.mil/Reference-Center/Publications/2021/04/15/MHS-Style-Guide",
    },
    "Defence Intellegence Agency": {
      logo: diaLogo,
      link: "https://www.dia.mil/Portals/110/Documents/FOIA/All PDFs/DIA-Instructions/DIA_Style_Manual_for_Intelligence_Production.pdf",
    },
    "Defence Logistics Agency": {
      logo: dlaLogo,
      link: "https://www.dla.mil/Portals/104/Documents/J5StrategicPlansPolicy/PublicIssuances/m5202.pdf",
    },
    "Health & Human Services": {
      logo: hhsLogo,
      link: "https://www.hhs.gov/web/policies-and-standards/web-style-guide/index.html",
    },
    "Department of Homeland Security": {
      logo: dhsLogo,
      link: "https://www.dhs.gov/xlibrary/dhsweb/_site/index.html",
    },
    "Veteran Affairs": {
      logo: vaLogo,
      link: "https://department.va.gov/style-guide/",
    },
    "Office of Elementary and Secondary Education": {
      logo: departmentOfEducationLogo,
      link: "https://oese.ed.gov/test/oii-style-guide-current-theme/",
    },
    "Energy Efficiency & Renewable Energy": {
      logo: eeseLogo,
      link: "https://www.energy.gov/eere/communicationstandards/eere-style-guide",
    },
    "Energy Information Administration": {
      logo: eiaLogo,
      link: "https://www.eia.gov/about/writing-styleguide.php",
    },
    "Environmental Protection Agency": {
      logo: epaLogo,
      link: "https://www.epa.gov/template/style-guide-test-page",
    },
    "Office for Immigration Review": {
      logo: immigrationReviewLogo,
      link: "https://www.ojp.gov/pdffiles1/nij/250404.pdf",
    },
    "Farm Service Agency": {
      logo: fsaLogo,
      link: "https://www.usda.gov/style-guide/writing-style",
    },
    "Federal Communications Commission": {
      logo: fccLogo,
      link: "https://www.fcc.gov/style-guide-elements",
    },
    "Federal Highway Administration": {
      logo: fhwaLogo,
      link: "https://highways.dot.gov/research/publication-tools",
    },
    "Federal Laboratory Consortium": {
      logo: flcLogo,
      link: "https://federallabs.org/about/media/flc-media-kit",
    },
    "Federal Protective Service": {
      logo: fpsLogo,
      link: "https://www.dhs.gov/xlibrary/dhsweb/_site/index.html",
    },
    "Federal Railroad Administration": {
      logo: fraLogo,
      link: "https://railroads.dot.gov/sites/fra.dot.gov/files/2021-04/Guidelines for Writing TRs 2021.pdf",
    },
    "Federal Trade Commission": {
      logo: ftcLogo,
      link: "https://www.ftc.gov/styleguide",
    },
    "Health Resources & Services Administration": {
      logo: hrsaLogo,
      link: "https://www.hrsa.gov/design/style-guide",
    },
    "Indian Health Service": {
      logo: ihsLogo,
      link: "https://www.ihs.gov/sites/rpms/themes/responsive2017/display_objects/documents/IHS_OIT_DocumentationStyleGuide_2021-09-10.pdf",
    },
    "Indoor Air Quality": {
      logo: iqmLogo,
      link: "https://www.epa.gov/template/style-guide-test-page",
    },
    "Institute of Museum and Library Services": {
      logo: imlsLogo,
      link: "https://www.imls.gov/imls-website-style-guide",
    },
    "Joint Chiefs of Staff": {
      logo: jcsLogo,
      link: "https://www.jcs.mil/Portals/36/Documents/JKO/JKO_Brand_Guidelines.pdf?ver=RRg0wAi6PL2Qqb3Pe0_gVA%3D%3D",
    },
    "DOJ Office of Justice Programs": {
      logo: dojJusticeProgramsLogo,
      link: "https://www.ojp.gov/pdffiles1/nij/250404.pdf",
    },
    "Bureau of Justice Assistance": {
      logo: bjaLogo,
      link: "https://bja.ojp.gov/doc/bja-style-guide.pdf",
    },
    "Office for Victims of Crime": {
      logo: ovicLogo,
      link: "https://ovc.ojp.gov/sites/g/files/xyckuh226/files/publications/infores/pubguidelines/2016-OVC-Style-Guide-508.pdf",
    },
    "Millennium Challenge Corporation": {
      logo: mccLogo,
      link: "https://www.mcc.gov/resources/doc/guidance-writing-guide-for-public-documents",
    },
    NASA: { logo: nasaLogo, link: "https://history.nasa.gov/styleguide.html" },
    "National Cancer Institute": {
      logo: nciLogo,
      link: "https://prevention.cancer.gov/style-guide",
    },
    "National Defense University": {
      logo: nduLogo,
      link: "https://ndupress.ndu.edu/Portals/68/Documents/jfq/style-guidelines.pdf",
    },
    "National Guard": {
      logo: nationalGuardLogo,
      link: "https://www.nationalguard.mil/Portals/31/Images/Image Gallery/Graphics/ARNG Marketing Logo/ARNG Branding Style Guidelines.pdf",
    },
    "Office of Disease Prevention and Health Promotion": {
      logo: odphpLogo,
      link: "https://health.gov/styleguide/content",
    },
    "National Institute of Corrections": {
      logo: nicLogo,
      link: "https://s3.amazonaws.com/static.nicic.gov/UserShared/NIC+Style+Guide.1.4.22.pdf",
    },
    "National Institute of Justice": {
      logo: nijLogo,
      link: "https://www.ojp.gov/pdffiles1/nij/250404.pdf",
    },
    "National Institutes of Health": {
      logo: nihLogo,
      link: "https://www.nih.gov/nih-style-guide",
    },
    "National Oceanic and Atmospheric Administration": {
      logo: noaaLogo,
      link: "https://oceanexplorer.noaa.gov/about/kit/styleguide.html",
    },
    "National Park Service Harpers Ferry Center": {
      logo: npsLogo,
      link: "https://www.nps.gov/subjects/hfc/hfc-editorial-style-guide.htm",
    },
    "National Science Foundation": {
      logo: nsfLogo,
      link: "https://www.nsf.gov/style/",
    },
    "Nuclear Regulatory Commission": {
      logo: nrcLogo,
      link: "https://www.nrc.gov/style-guide.html",
    },
    "U.S. Military Academy": {
      logo: usmaLogo,
      link: "https://usarmywestpoint.sharepoint.com/sites/pao.webteam/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fpao%2Ewebteam%2FShared%20Documents%2FUSMA%20Style%20Guide%2Epdf&parent=%2Fsites%2Fpao%2Ewebteam%2FShared%20Documents",
    },
    "U.S. Government Publishing Office Style Manual": {
      logo: usgpoStyleManualLogo,
      link: "https://www.govinfo.gov/content/pkg/GPO-STYLEMANUAL-2016/pdf/GPO-STYLEMANUAL-2016.pdf",
    },
  };

  return map;
};

export const getPlaceHolderIcon = () => {
  return placeHolderLogo;
};
