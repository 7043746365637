import { Box, Flex, useTheme } from "@chakra-ui/react";
import * as S from "./styles";

export const HeroComponent = ({ onContactUsClick }) => {
  const theme = useTheme();
  const { primary, secondary, accentOne } = theme.colors;
  const { heading } = theme.fonts;

  return (
    <S.StyledHeroContainer bgColor={secondary}>
      <Flex>
        <S.StyledOuterFlexBoxContainer>
          <S.StyledHeroHeading>
            Plain language makes it easier for the public to read, understand,
            and use government communications.
          </S.StyledHeroHeading>
          <S.StyledSubtitleContainer>
            <S.StyledSubtitleText>
              At the click of a button, harness the power of AI to ensure your
              documents meets the Plain Language Act, making documents more
              accessible to all.
            </S.StyledSubtitleText>
          </S.StyledSubtitleContainer>
          <S.StyledHeroButton
            bgColor={accentOne}
            color={primary}
            fontFamily={heading}
            onClick={onContactUsClick}
          >
            Start your journey to clarity
          </S.StyledHeroButton>
        </S.StyledOuterFlexBoxContainer>
        {/* Empty space */}
        <S.StyledEmptySpaceContainer />
      </Flex>
    </S.StyledHeroContainer>
  );
};
